import React from 'react'
import './LeadManagementfeature.css'

export default function LeadManagementfeature() {
    return (
        <>
            <div className="leadManagementfeature" data-scroll-section>
                <div className="container cust-container">
                    <div className="inner-heading-box">

                        <h2>Lead and deal management features</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>Efficient lead and deal management is crucial for driving sales and ensuring business growth. Our CRM system offers a comprehensive suite of features designed to streamline the entire process, from capturing leads to closing deals, enabling your sales team to work more effectively and close more deals.</p>
                    </div>


                    <div className="content-box">
                        <div className="row g-5 g-md-3">
                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/lead-capture.png')} alt="" />
                                    <div className="content">
                                        <h5>Lead Capture</h5>
                                        <p>Automatically collect leads from various sources such as web forms, emails, and social media.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/lead-scoring.png')} alt="" />
                                    <div className="content">
                                        <h5>Lead Scoring</h5>
                                        <p>Prioritize leads based on predefined criteria and their likelihood to convert, helping focus on high-potential opportunities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/pipline.png')} alt="" />
                                    <div className="content">
                                        <h5>Pipeline Management</h5>
                                        <p>Visualize and manage the entire sales pipeline to track the progress of deals from lead to closure.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/deal-tracking.png')} alt="" />
                                    <div className="content">
                                        <h5>Deal Tracking</h5>
                                        <p>Monitor and manage all deal-related activities, ensuring no opportunity is overlooked or forgotten.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/follow-up.png')} alt="" />
                                    <div className="content">
                                        <h5>Automated Follow-Ups</h5>
                                        <p>Schedule and automate follow-up emails and tasks to maintain consistent communication with leads and prospects.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/customise.png')} alt="" />
                                    <div className="content">
                                        <h5>Customizable Sales Stages</h5>
                                        <p>Define and customize sales stages to match your unique sales process, ensuring a tailored approach to deal management.

                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/reporting.png')} alt="" />
                                    <div className="content">
                                        <h5>Reporting and Analytics</h5>
                                        <p>Generate detailed reports and insights on lead and deal performance, helping to refine strategies and improve conversion rates.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/logging.png')} alt="" />
                                    <div className="content">
                                        <h5>Activity Logging</h5>
                                        <p>Keep a comprehensive log of all interactions and activities related to leads and deals for easy reference and accountability.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/feature/lead/contact.png')} alt="" />
                                    <div className="content">
                                        <h5>Contact Management</h5>
                                        <p>Maintain organized and up-to-date contact information for leads and deals to facilitate better communication and relationship-building.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
