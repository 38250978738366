import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Home from '../pages/Home.js'
import ContactUs from '../pages/ContactUs.js'
import Pricing from '../pages/Pricing.js'
import FeaturePage from '../pages/FeaturePage.js'
import About from '../pages/AboutPage.js'
import WhyZimplCrm from '../pages/WhyZimplCrm.js'
import Resources from '../pages/Resources.js'
import NotFound from '../components/notFound/NotFound.js';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import ScrollToTop from '../CustomHook/ScrollToTop.js'
import BacktoTop from '../components/backToTop/BacktoTop.js'



// import Layout from '../LayoutRouts/Layout'


export default function LayoutRouts() {

    return (
        <>

            {/* <Layout> */}

            <Router>


                <BacktoTop />
                <ScrollToTop />


                <Container>
                    <Navbar />
                </Container>

                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/contactus' element={<ContactUs />}></Route>
                    <Route path='/pricing' element={<Pricing />}></Route>
                    <Route path='/features' element={<FeaturePage />}></Route>
                    <Route path='/about' element={<About />}></Route>
                    <Route path='/whyZimplCrm' element={<WhyZimplCrm />}></Route>
                    <Route path='/resources' element={<Resources />}></Route>
                    <Route path='*' element={<NotFound />} />
                </Routes>
                <Footer />



            </Router>


            {/* </Layout> */}
        </>
    )
}
