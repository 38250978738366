import React from 'react'
import './NotFound.css'

export default function NotFound() {
  return (
    <>
      <div className="notfound">
        <img className='img-fluid' src={require('../../assets/images/404/bot.png')} alt="" />
        <h1>404</h1>
        <h2>Ooops... &nbsp; &nbsp; We cannot find this page</h2>

        <a href="">Go to Home page</a>
        
      </div>
    </>
  )
}
