import React from 'react'
import Banner from '../components/banner/Banner'
import Client from '../components/client/Client'
import Features from '../components/features/Features'
import Why from '../components/why/Why'
import Productivity from '../components/productivity/Productivity'
import Contact from '../components/contact/Contact'
import HomePageFaq from '../components/faq/HomePageFaq'
import Blogs from '../components/blogs/Blogs'
// import VideoTestimonial from '../components/testimonial/VideoTestimonial'
import { Helmet } from "react-helmet";
import AOSWrapper from '../CustomHook/AOSWrapper.js'

export default function Home() {
    return (
        <>

            {/* META TAGS */}
            <Helmet>
                <title>Best CRM Software in India | All in One CRM Software | Zimplcrm</title>
                <meta name="description" content="Explore the best CRM software in India with Zimplcrm. Enhance customer relationships, streamline processes, and boost productivity with our all-in-one solution." />
                <meta name="keywords" content="low budget CRM services in India, budget friendly CRM software services India, CRM development services, CRM solution, lead management software, best CRM software for lead management, CRM development company, CRM for small business, CRM for medium business, custom CRM development services" />
                <link rel="canonical" href="https://zimplcrm.com/" />
            </Helmet>


            <Banner />
            <Client />
            <Features />
            <Why />
            <Productivity />
            {/* <VideoTestimonial/> */}


            {/* <Blogs /> */}
            <HomePageFaq />
            <Contact />



        </>
    )
}



