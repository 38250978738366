import React from 'react'
import WhyZimpleCrmBanner from '../components/whyZimpleCrmBanner/WhyZimpleCrmBanner'
import CtaTwo from '../components/cta/CtaTwo'
import NextLevel from '../components/nextLevel/NextLevel'
import Support from '../components/support/Support.js'
import CompareCrm from '../components/compareCRM/CompareCrm.js'
import Testimonial2 from '../components/testimonial/Testimonial2.js'
import { Helmet } from "react-helmet";


import { Container } from 'react-bootstrap'

export default function WhyZimplCrm() {
    return (
        <>

          {/* META TAGS */}
          <Helmet>
                <title>CRM for Small Business | CRM for Medium Business | Zimplcrm </title>
                <meta name="description" content="Drive business growth with Zimplcrm's powerful CRM for small and medium businesses. Enhance productivity, nurture leads, and achieve business success with ease." />
                <meta name="keywords" content="low budget CRM services in India, budget friendly CRM software services India, CRM development services, CRM solution, lead management software, best CRM software for lead management, CRM development company, CRM for small business, CRM for medium business, custom CRM development services" />
                <link rel="canonical" href="https://zimplcrm.com/whyZimplCrm" />
            </Helmet>

            <Container>
                <WhyZimpleCrmBanner />
            </Container>

            <Container className='cust-container'>
                <NextLevel />
                <Support />
                <CompareCrm/>
            </Container>
                <Testimonial2/>

            <CtaTwo />
        </>
    )
}
