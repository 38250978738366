import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './Faq.css';

export default function Faq({ content }) {
    // Split the content array into two parts
    const leftContent = content.slice(0, 5);  // First 5 items
    const rightContent = content.slice(5);    // Remaining items

    return (
        <div className="container" data-scroll-section>
            <div className="faq">
                <div className="heading-box">
                    <h2>FAQ</h2>
                </div>
                <div className="faq-box">
                    <div className="row">
                        <div className="col-lg-6 left">
                            {leftContent.map((item, index) => (
                                <div className="col-lg-12" key={index}>
                                    <Accordion>
                                        <Accordion.Item eventKey={`left-${index}`}>
                                            <Accordion.Header>{item.question}</Accordion.Header>
                                            {item.answer && (
                                                <Accordion.Body>
                                                    {item.answer}
                                                </Accordion.Body>
                                            )}
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            ))}
                        </div>

                        <div className="col-lg-6 right">
                            {rightContent.map((item, index) => (
                                <div className="col-lg-12" key={index}>
                                    <Accordion>
                                        <Accordion.Item eventKey={`right-${index}`}>
                                            <Accordion.Header>{item.question}</Accordion.Header>
                                            {item.answer && (
                                                <Accordion.Body>
                                                    {item.answer}
                                                </Accordion.Body>
                                            )}
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
