import React from 'react';
import './Client.css';
// import useAOS from '../../CustomHook/useAOS';

import 'swiper/css/free-mode';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/bundle';

import Card from 'react-bootstrap/Card';


export default function Client() {
    return (
        <>
            <div className="client" data-scroll-section>
                <div className="container cust-container">
                    <div className="heading-box">
                        <h2>
                            Over 100+ top  businesses growing with ZimplCRM
                        </h2>
                    </div>

                    <div className="items">


                        <Swiper

                            loop={true}
                            grabCursor={true}
                            mousewheel={true}
                            freeMode={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[FreeMode, Autoplay]}
                            className="clientSlider"
                            slidesPerView={3}

                            breakpoints={{
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                },
                                999: {
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                },
                                1024: {
                                    spaceBetween: 10,
                                    slidesPerView: 7,
                                },


                                1440: {
                                    spaceBetween: 10,
                                    slidesPerView: 7,
                                }
                            }}

                        >

                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client1.png')} />
                                    </div>
                                </Card>
                            </SwiperSlide>


                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client4.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client5.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client6.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client7.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>



                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client8.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client10.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Card>
                                    <div className='img-box'>
                                        <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/client/client11.png')} />
                                    </div>

                                </Card>
                            </SwiperSlide>


                        </Swiper>




                        {/* <img className='img-fluid' src={require("../../assets/images/client/client1.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client2.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client3.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client4.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client5.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client6.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client7.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client8.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client9.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client10.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client11.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client12.png")} alt="" />
                        <img className='img-fluid' src={require("../../assets/images/client/client13.png")} alt="" /> */}


                    </div>
                </div>

            </div>
        </>
    )
}
