import React from 'react'
import Contact from '../components/contact/Contact'
import PricePageFaq from '../components/faq/PricePageFaq.js'
import Testimonial2 from '../components/testimonial/Testimonial2'
import Container from 'react-bootstrap/Container';
import OfficeLocation from '../components/officeLocation/OfficeLocation.js';
import { Helmet } from "react-helmet";

export default function ContactUs() {
    return (
        <>

            <Helmet>
                <title>Popular CRM Software | Zimplcrm | Contact us</title>
                <meta name="description" content="Explore a popular CRM software to enhance your business operations. Get in touch with us to learn how we can help streamline your sales and customer management." />
                <meta name="keywords" content="Popular CRM Software, Best CRM Management Software, CRM platform, Best CRM Software in India, Customer Relationship Management, CRM Solution" />
                <link rel="canonical" href="https://zimplcrm.com/contactus" />
            </Helmet>

            <Container fluid>
                <Contact></Contact>
            </Container>

            <OfficeLocation />
            <Testimonial2 />
            <Container>
                <PricePageFaq />
            </Container>

        </>
    )
}
