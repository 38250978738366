import React from 'react'
import './Testimonial2.css'
import Container from 'react-bootstrap/Container';

import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';

import Card from 'react-bootstrap/Card';

export default function Testimonial2() {
    return (
        <>
            <div className="testimonial2" data-scroll-section>
                <Container>
                    <div className="heading-box">
                        <h5>Trusted by 100+ companies</h5>
                    </div>

                    <div className="company-box">
                        <div className="box">
                            <img className='company-logo' src={require('../../assets/images/testimonial/company1.png')} alt="" />
                            <h5><span>5</span>/5</h5>
                            <img src={require('../../assets/images/testimonial/5stars.png')} alt="" />

                        </div>

                        <div className="box">
                            <img  className='company-logo' src={require('../../assets/images/testimonial/company2.png')} alt="" />
                            <h5><span>5</span>/5</h5>
                            <img src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                        </div>

                        <div className="box">
                            <img  className='company-logo' src={require('../../assets/images/testimonial/company3.png')} alt="" />
                            <h5><span>5</span>/5</h5>
                            <img src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                        </div>

                        <div className="box">
                            <img  className='company-logo' src={require('../../assets/images/testimonial/company4.png')} alt="" />
                            <h5><span>5</span>/5</h5>
                            <img src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                        </div>

                        <div className="box">
                            <img  className='company-logo' src={require('../../assets/images/testimonial/company5.png')} alt="" />
                            <h5><span>5</span>/5</h5>
                            <img src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                        </div>
                    </div>

                </Container>

                <Container fluid>
                    <Swiper
                        loop={true}
                        grabCursor={true}
                        mousewheel={true}
                        freeMode={true}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        // }}
                        modules={[FreeMode,Autoplay]}
                        className="mySwiper"

                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },

                            999: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },

                            // when window width is >= 1024px
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },

                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 30
                            }
                        }}

                    >
                        <SwiperSlide>
                            <Card>
                                <Card.Body>
                                    <img className='rating-star' src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                                    <Card.Text>ZimplCRM has transformed how we manage our customer relationships. The seamless integrations and powerful analytics have helped us make data-driven decisions. It's an invaluable tool for our team.</Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="auther">
                                        <h6>John</h6>
                                        <p>February 12, 2024</p>
                                    </div>
                                <img className='author-img' src={require('../../assets/images/testimonial/author5.png')} alt="" />
                                </Card.Footer>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card>
                                <Card.Body>
                                    <img className='rating-star' src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                                    <Card.Text>As a growing business, we needed a scalable CRM solution. ZimplCRM's customizable features and robust reporting capabilities have exceeded our expectations. Highly recommended!</Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="auther">
                                        <h6>Emily Johnson</h6>
                                        <p>March 5, 2024</p>
                                    </div>
                                    <img className='author-img' src={require('../../assets/images/testimonial/author1.png')} alt="" />
                                </Card.Footer>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card>
                                <Card.Body>
                                    <img className='rating-star' src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                                    <Card.Text>Switching to ZimplCRM was one of the best decisions we've made. The user-friendly interface and comprehensive calendar feature have streamlined our workflow and improved our efficiency significantly.</Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="auther">
                                        <h6>Michael Brown</h6>
                                        <p>April 18, 2024</p>
                                    </div>
                                <img className='author-img' src={require('../../assets/images/testimonial/author2.png')} alt="" />
                                </Card.Footer>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card>
                                <Card.Body>
                                    <img className='rating-star' src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                                    <Card.Text>ZimplCRM has been a game-changer for our sales team. The automated follow-up campaigns and detailed reports have helped us stay on top of our sales pipeline and close more deals.</Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="auther">
                                        <h6>Sophia Williams</h6>
                                        <p>May 10, 2024</p>
                                    </div>
                                <img  className='author-img' src={require('../../assets/images/testimonial/author4.png')} alt="" />
                                </Card.Footer>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card>
                                <Card.Body>
                                    <img className='rating-star' src={require('../../assets/images/testimonial/5stars.png')} alt="" />
                                    <Card.Text>From lead management to deal tracking, ZimplCRM covers all our needs. The support team is also incredibly responsive and helpful. It's the perfect CRM solution for our business.</Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <div className="auther">
                                        <h6>David Lee</h6>
                                        <p>June 22, 2024</p>
                                    </div>
                                <img className='author-img' src={require('../../assets/images/testimonial/author3.png')} alt="" />
                                </Card.Footer>
                            </Card>
                        </SwiperSlide>
                    </Swiper>

                </Container>

            </div >
        </>
    )
}
