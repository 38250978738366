import React from 'react'
import './Feature.css'

import Container from 'react-bootstrap/Container';

export default function FeatureBanner() {
    return (
        <>
            <div className="feature-banner" data-scroll-section>
                <div className='container cust-container'>

                <div className="bg-box">
                    <div className="row">


                        <div className="col-lg-6">

                            <div className="content-box">
                                <h1>
                                    Comprehensive CRM Solutions
                                </h1>
                                <p>
                                    Discover how our CRM software can transform your business operations, offering a wide range of features designed to enhance efficiency and drive growth. Watch our quick video overview.
                                </p>

                                <button className='btn-primary'>
                                    Watch Video
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="right">
                            <img className='banner-item4 img-fluid' src={require('../../assets/images/feature/banner/banner-item4.png')} alt="" />
                            <img className='banner-item1 img-fluid' src={require('../../assets/images/feature/banner/banner-item1.png')} alt="" />
                            <img className='banner-item2 img-fluid' src={require('../../assets/images/feature/banner/banner-item2.png')} alt="" />
                            <img className='banner-item3 img-fluid' src={require('../../assets/images/feature/banner/banner-item3.png')} alt="" />
                            </div>
                        </div>

                    </div>


                </div>

                </div>
            </div>
        </>
    )
}
