import React from 'react'
import './Support.css'

export default function Support() {
    return (
        <>
            <div className="support" data-scroll-section>
                <div className="container cust-container">
                    <div className="row g-lg-5">
                        <div className="col-lg-12 col-md-12 col-xl-8">
                            <div className="left">
                                <div className="inner-subheading-box">
                                    <h3>Superior Customer Support</h3>
                                    <p>We prioritize your success by offering top-notch support services tailored to your needs. Our dedicated support team is available to assist you with any questions or concerns, ensuring you get the most out of Zimpl CRM. Whether you need help with setup, troubleshooting, or optimizing your usage, we are here to provide the assistance you need to succeed.</p> <p> Our comprehensive support resources include tutorials, FAQs, guides, and one-on-one consultations to ensure you have all the tools necessary to make the most of your CRM system. Trust our expert team to help you navigate any challenges and achieve your business goals with Zimpl CRM.</p>
                                </div>

                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon1.png')} alt="" />
                                            </div>
                                            <h5>Availability: </h5>
                                            <p>Our support team is available around the clock to assist you.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon2.png')} alt="" />
                                            </div>
                                            <h5>Expertise:</h5>
                                            <p>Gain insights with up-to-the-minute data and reports.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon1.png')} alt="" />
                                            </div>
                                            <h5>Responsiveness:</h5>
                                            <p>Get quick and effective solutions to your issues.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon2.png')} alt="" />
                                            </div>
                                            <h5>Resources:<sup>*</sup></h5>
                                            <p>Access a wealth of resources including tutorials, FAQs, and guides.</p>

                                        </div>
                                    </div>


                             

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xl-4 text-center">
                            <div className="right">

                            </div>
                            {/* <img className='img-fluid' src={require('../../assets/images/whyZimplCrm/support.png')} alt="" /> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
