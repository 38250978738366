import React from 'react'
import './WhyZimpleCrmBanner.css'

import {Link} from 'react-router-dom'

export default function WhyZimpleCrmBanner() {
    return (
        <>

            <div className="WhyZimpleCrmBanner" data-scroll-section>
                <div className="row g-5">
                    <div className="col-lg-12 col-xl-6 col-md-12">
                        <div className="left">
                            <h1>Why Zimpl CRM?</h1>
                            <h5>Streamlined Business Management </h5>
                            <p>Our CRM system is designed to cater to the diverse needs of small and medium businesses across various industries. Zimpl CRM combines user-friendly design with powerful features to ensure you get the best return on your investment. Whether you need a <a href="/features">CRM for small businesses</a> or a <a href="about">CRM for medium businesses</a>, Zimpl CRM has you covered. </p>

                            <p>
                                Zimpl CRM offers <b>UNLIMITED USER ACCESS AT A FIXED PRICE!</b>, making it the perfect choice for growing businesses. Experience unparalleled efficiency and streamlined operations with our comprehensive toolset tailored to your unique requirements.
                            </p>

                            <div>
                                <button className="btn-primary me-4">Watch Video</button>
                                <Link to='/contactus' className="btn-primary-outline">Ask Demo</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12  col-xl-6 col-md-12">
                        <div className="right">
                            <img className='img-fluid' src={require('../../assets/images/whyZimplCrm/banner.png')} alt="" />

                            <div className="button-box">
                                <a className="button is-play" href="https://www.youtube.com/@zimplcrm">
                                    <div className="button-outer-circle has-scale-animation"></div>
                                    <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                                    <div className="button-icon is-play">
                                        <svg height="100%" width="100%" fill="#fff">
                                            <polygon className="triangle" points="5,0 30,15 5,30" viewBox="0 0 30 15"></polygon>
                                            <path className="path" d="M5,0 L30,15 L5,30z" fill="none" stroke="#fff" stroke-width="1"></path>
                                        </svg>
                                    </div></a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
