import React from 'react'
import './HistoryOfCrm.css'

export default function HistroyOfCrm() {
    return (
        <>
            <div className="historyOfCrm" data-scroll-section>
                <div className="container cust-container">

                    <div className="row g-lg-5">
                        <div className="col-lg-4">
                            <div className="left">

                            </div>
                            {/* <img className='img-fluid' src={require('../../assets/images/about/ourHistory.png')} alt="" /> */}
                        </div>

                        <div className="col-lg-8">

                            <div className="right">
                                <div className="inner-subheading-box">
                                    <h1>The Evolution of customer relationship management solutions</h1>
                                </div>

                                <p className='heading-para'>
                                    Customer Relationship Management (CRM) has evolved to incorporate a wide array of features that enhance the way businesses interact with their customers. From its beginnings with basic contact management, CRM systems have grown to include comprehensive functionalities that address various business needs. Modern <a href="/">customer relationship management solutions</a> are now integral to optimizing customer relationships and driving business success.
                                </p>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Store and manage detailed customer information in one centralized database.
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Automate sales tasks such as lead tracking, pipeline management, and follow-ups.
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Streamline marketing campaigns, email marketing, and lead nurturing processes.                                    </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Provide robust tools for tracking customer service interactions and resolving issues efficiently.                                   </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Access CRM data anytime, anywhere with cloud-based solutions.                                   </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Utilize mobile applications for on-the-go access to customer data and CRM functionalities. </p>
                                            </li>


                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Streamline business processes with automated workflows that reduce manual tasks and improve efficiency. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Integrate social media channels to enhance customer engagement and track interactions.
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Leverage AI and machine learning for predictive analytics, personalized customer experiences, and data-driven insights.
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Create personalized dashboards to display key metrics and data relevant to your business. </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Generate detailed reports and gain insights into customer behaviors and business performance.</p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Seamlessly connect CRM systems with other business tools and applications. </p>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
