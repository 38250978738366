import React from 'react'
import './WeHelp.css'

export default function WeHelp() {
    return (
        <>
            <div className="weHelp">
                <div className="container">
                    <div className="inner-heading-box">
                        <h2>Extend ZimplCRM’s Functionality</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>Unlock additional capabilities by integrating ZimplCRM with external services and utilizing our robust add-ons, designed to enhance and customize your CRM experience.</p>
                    </div>

                    <div className="content-box">
                        <div className="row g-5">
                            <div className="col-lg-4">
                                <div className="item">
                                    <div className="card">
                                        <img className='img-fluid' src={require('../../assets/images/resources/weHelp1.png')} alt="" />
                                        <div className="card-body">
                                            <h5 className="card-title">Seamless Integrations</h5>
                                            <p className="card-text">Connect with tools like Twilio and WhatsApp to enhance communication capabilities.</p>
                                        </div>
                                    </div>

                                    <div className="bottom-content">
                                        <h5>Marketing Insights</h5>
                                        <p>Gain valuable insights into your marketing efforts to optimize campaigns and drive better results. </p>
                                        <a className="weHelp-btn" href="https://login.zimplcrm.com">
                                            View All Articles
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item">
                                    <div className="card">
                                        <img className='img-fluid' src={require('../../assets/images/resources/weHelp2.png')} alt="" />
                                        <div className="card-body">
                                            <h5 className="card-title">Document Management</h5>
                                            <p className="card-text">Organize and share documents directly within ZimplCRM.<br/><br/></p>
                                        </div>
                                    </div>

                                    <div className="bottom-content">
                                        <h5>Sales Insights</h5>
                                        <p>Explore articles on analyzing sales data, improving strategies, and boosting performance effectively. </p>
                                        <a className="weHelp-btn" href="https://login.zimplcrm.com">
                                            View All Articles
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="item">
                                    <div className="card">
                                        <img className='img-fluid' src={require('../../assets/images/resources/weHelp3.png')} alt="" />
                                        <div className="card-body">
                                            <h5 className="card-title">Integrate Generative AI*</h5>
                                            <p className="card-text">Enhance your CRM with AI for smarter customer interactions and better data analysis.</p>
                                        </div>
                                    </div>

                                    <div className="bottom-content">
                                        <h5>Product Updates</h5>
                                        <p>Stay informed about the latest features and improvements in ZimplCRM to maximize your usage.</p>
                                        <a className="weHelp-btn" href="https://login.zimplcrm.com">
                                            See All Updates
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}
