import React from 'react'
import './Cta.css'
import { Link } from 'react-router-dom'

export default function ctaOne() {
    return (
        <>
            <div className="container cust-container" data-scroll-section>
                <div className="ctaone">
                    <img className='img-fluid lg' src={require('../../assets/images/cta/ctaOne-bg.png')} alt="" />
                    <img className='img-fluid sm' src={require('../../assets/images/cta/ctaOne-bg-mob.png')} alt="" />
                    <div className="content">
                        <h2>Join the Sales Experts</h2>
                        <p>Free 14-day Professional plan trial. Full access. No credit card required</p>

                        <Link to='/contactus'>
                            Start for Free
                        </Link>
                    </div>
                </div>
            </div>

        </>
    )
}
