import React from 'react'
import './Portfolio.css'
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode,Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import Card from 'react-bootstrap/Card';

export default function Portfolio() {
    return (
        <>
            <div className="portfolio">
                <div className="container cust-container">
                    <div className="heading-box">
                        <div className="left">
                            <h3>Real Stories of Success</h3>
                            <p>Explore how ZimplCRM is transforming businesses across various industries through detailed user case studies. These real-life examples showcase the versatility and effectiveness of our CRM solution in addressing unique challenges and driving remarkable growth. </p>
                        </div>
                        <div className="right">
                            <a className='borderd-btn'>
                            View All  Customer Examples
                            </a>
                        </div>
                    </div>

                    <div className="content-box">
                    <Swiper
                 spaceBetween= {50}
                 loop={true}
                 grabCursor={true}
                 mousewheel={true}
                 freeMode={true}
                 autoplay={{
                     delay: 2000,
                     disableOnInteraction: false,
                 }}
                 modules={[FreeMode,Autoplay]}
                 className="portFolioSwiper"

                 

                 breakpoints={{
                     640: {
                         slidesPerView: 2,
                         spaceBetween: 50
                     },
                     768: {
                         slidesPerView: 2,
                         spaceBetween: 50
                     },
                     999: {
                         slidesPerView: 2,
                         spaceBetween: 50
                     },
                     1024: {
                         spaceBetween: 30,
                         slidesPerView: 2,
                     },

                     1200: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                    },
         
         
                     1440: {
                         spaceBetween: 20,
                         slidesPerView: 3,
                     }
                 }}

             >
          
                 <SwiperSlide>
                     <Card>
                         <div className='img-box'>
                         <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/portfolio/event-managment.png')} />
                         <img className='img-fluid ovel-shape' src={require('../../assets/images/resources/ovel-shape.png')} alt="" />
                         <img className='img-fluid disc-shape1' src={require('../../assets/images/resources/disc-shape1.png')} alt="" />
                         <img className='img-fluid disc-shape2' src={require('../../assets/images/resources/disc-shape2.png')} alt="" />
                         
                         </div>

                         <Card.Body>
                             <Card.Title>Event Management</Card.Title>
                             <Card.Text>Stellar Events was struggling to manage client interactions, track event progress, and coordinate tasks among team members...</Card.Text>

                         </Card.Body>

                         
                     </Card>
                 </SwiperSlide>

                 <SwiperSlide>
                     <Card>
                         <div className='img-box'>
                         <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/portfolio/realestate.png')} />
                         <img className='img-fluid ovel-shape' src={require('../../assets/images/resources/ovel-shape.png')} alt="" />
                         <img className='img-fluid disc-shape1' src={require('../../assets/images/resources/disc-shape1.png')} alt="" />
                         <img className='img-fluid disc-shape2' src={require('../../assets/images/resources/disc-shape2.png')} alt="" />
                         
                         </div>
                         <Card.Body>
                         <Card.Title>Real Estate</Card.Title>
                         <Card.Text>Prime Realty faced difficulties in managing property listings, client interactions, and sales processes...	</Card.Text>

                         </Card.Body>

                      
                     </Card>
                 </SwiperSlide>

                 <SwiperSlide>
                     <Card>
                         <div className='img-box'>
                         <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/portfolio/healthcare.png')} />
                         <img className='img-fluid ovel-shape' src={require('../../assets/images/resources/ovel-shape.png')} alt="" />
                         <img className='img-fluid disc-shape1' src={require('../../assets/images/resources/disc-shape1.png')} alt="" />
                         <img className='img-fluid disc-shape2' src={require('../../assets/images/resources/disc-shape2.png')} alt="" />
                         
                         </div>

                         <Card.Body>
                         <Card.Title>Healthcare Clinic</Card.Title>
                         <Card.Text>Healthy Life Clinic faced challenges in managing patient records, appointment scheduling, and follow-ups...</Card.Text>

                         </Card.Body>
                         
                     </Card>
                 </SwiperSlide>

                 <SwiperSlide>
                     <Card>
                         <div className='img-box'>
                         <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/portfolio/pestcontrol.png')} />

                         <img className='img-fluid ovel-shape' src={require('../../assets/images/resources/ovel-shape.png')} alt="" />
                         <img className='img-fluid disc-shape1' src={require('../../assets/images/resources/disc-shape1.png')} alt="" />
                         <img className='img-fluid disc-shape2' src={require('../../assets/images/resources/disc-shape2.png')} alt="" />

                         </div>

                         <Card.Body>
                             <Card.Title>Pest Control Agency</Card.Title>
                             <Card.Text>SafeGuard Pest Control encountered difficulties in managing client schedules, tracking service visits...</Card.Text>

                         </Card.Body>

                         
                     </Card>
                 </SwiperSlide>

                 <SwiperSlide>
                     <Card>
                         <div className='img-box'>
                         <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/portfolio/product-dealer.png')} />

                         <img className='img-fluid ovel-shape' src={require('../../assets/images/resources/ovel-shape.png')} alt="" />
                         <img className='img-fluid disc-shape1' src={require('../../assets/images/resources/disc-shape1.png')} alt="" />
                         <img className='img-fluid disc-shape2' src={require('../../assets/images/resources/disc-shape2.png')} alt="" />
                         
                         </div>
                         <Card.Body>
                         <Card.Title>Product Dealer</Card.Title>
                         <Card.Text>TechAutomation Solutions faced challenges in managing their extensive product catalog, tracking sales leads...</Card.Text>

                         </Card.Body>

                      
                     </Card>
                 </SwiperSlide>

                 <SwiperSlide>
                     <Card>
                         <div className='img-box'>
                         <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/portfolio/waterproofing.png')} />
                         <img className='img-fluid ovel-shape' src={require('../../assets/images/resources/ovel-shape.png')} alt="" />
                         <img className='img-fluid disc-shape1' src={require('../../assets/images/resources/disc-shape1.png')} alt="" />
                         <img className='img-fluid disc-shape2' src={require('../../assets/images/resources/disc-shape2.png')} alt="" />
                         
                         </div>

                         <Card.Body>
                         <Card.Title>Waterproofing Agency</Card.Title>
                         <Card.Text>AquaShield Waterproofing Solutions faced issues with managing client appointments, tracking project progress...</Card.Text>

                         </Card.Body>
                         
                     </Card>
                 </SwiperSlide>

            

            

             </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}
