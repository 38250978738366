import React from 'react'
import './Feature.css'



export default function Feature1() {
  return (
    <>
      <div className="featureOne" data-scroll-section>
        <div className="container cust-container">
          <div className="inner-heading-box">
            <h2>Powerful Lead and Deal Management</h2>
            <img src={require('../../assets/images/pricing/underline.png')} alt="" />
            <p>Efficiently manage your leads and deals with our advanced tools, designed to streamline your sales process and boost productivity. Whether you're capturing new leads or tracking ongoing deals, our CRM ensures you stay organized and focused.</p>
          </div>
        </div>
        <div className="content-box">
          <div className="container cust-container">
            <div className="row gx-5">
              <div className="col-xl-6 col-lg-12 left">

                {/* <img className='img-fluid' src={require('../../assets/images/feature/featureone.png')} alt="" /> */}

                <div className="img-box">
                  <img className='img-fluid' src={require('../../assets/images/feature/featureone2.png')} alt="" />
                  <img className='img-fluid shape1' src={require('../../assets/images/feature/featureone3.png')} alt="" />
                  <img className='img-fluid shape2' src={require('../../assets/images/feature/featureone1.png')} alt="" />
                </div>

              </div>
              <div className="col-xl-6 col-lg-12  d-lg-flex justify-content-end">
                <div className="right">
                  <h2>CRM Features: Empowering Your Business Management.</h2>
                  <p>Our CRM software transforms your business processes with powerful <a href="/resources">crm features</a> that maximize efficiency and growth. Streamline interactions, manage leads, track customer interactions, and view performance metrics. Our user-friendly CRM solution lets you make informed decisions and propel your business forward. Watch our quick video overview!</p>

                  <div className="row">

                    <div className="col-lg-6 col-md-6">
                      <div className="item">
                        <div className="img-box">
                          <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                          <svg className='img-fluid icon' width="51" height="44" viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.4813 31.7908L4.4 35L5.29032 29.377C3.17829 27.4303 2 24.7262 2 21.7368C2 15.807 6.6362 11 14 11C21.3638 11 26 15.807 26 21.7368C26 27.6666 21.3638 32.4737 14 32.4737C12.3496 32.4737 10.8362 32.2322 9.4813 31.7908Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.6722 20.332C9.40858 20.332 10.0055 20.929 10.0055 21.6654C10.0055 22.3491 9.49081 22.9127 8.82769 22.9897L8.6722 22.9987C7.92996 22.9987 7.33301 22.4017 7.33301 21.6654C7.33301 20.9816 7.84773 20.418 8.51085 20.341L8.6722 20.332Z" fill="black" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0052 20.332C14.7416 20.332 15.3385 20.929 15.3385 21.6654C15.3385 22.3491 14.8238 22.9127 14.1607 22.9897L14.0052 22.9987C13.263 22.9987 12.666 22.4017 12.666 21.6654C12.666 20.9816 13.1807 20.418 13.8439 20.341L14.0052 20.332Z" fill="black" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3392 20.332C20.0756 20.332 20.6725 20.929 20.6725 21.6654C20.6725 22.3491 20.1578 22.9127 19.4947 22.9897L19.3392 22.9987C18.597 22.9987 18 22.4017 18 21.6654C18 20.9816 18.5147 20.418 19.1778 20.341L19.3392 20.332Z" fill="black" />
                          </svg>

                        </div>
                        <h5>All-in-One Communication: </h5>
                        <p>Manage all interactions with leads and accounts in a single, unified inbox.</p>

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="item">
                        <div className="img-box">
                          <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                          <svg className='img-fluid icon' width="50" height="44" viewBox="0 0 50 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 17.6373C2 13.6912 4.3 12 7.75 12H19.25C22.7 12 25 13.6912 25 17.6373V25.5294C25 29.4755 22.7 31.1667 19.25 31.1667H7.75" stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.25 18.2012L15.6505 21.0198C14.466 21.9443 12.5225 21.9443 11.338 21.0198L7.75 18.2012" stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2 26.6562H8.9" stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2 22.1465H5.45" stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <h5>SMS Integration:<sup>*</sup> </h5>
                        <p>Integrate SMS capabilities for timely and efficient communication.</p>

                      </div>
                    </div>


                    <div className="col-lg-6 col-md-6">
                      <div className="item">
                        <div className="img-box">
                          <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                          <svg className='img-fluid icon' width="50" height="44" viewBox="0 0 50 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8599 23.8C18.0164 23.8 20.5752 26.3744 20.5752 29.55V31.85C20.5752 32.4851 20.0635 33 19.4322 33C18.8009 33 18.2891 32.4851 18.2891 31.85V29.55C18.2891 27.6446 16.7538 26.1 14.8599 26.1H5.71534C3.82145 26.1 2.28614 27.6446 2.28614 29.55V31.85C2.28614 32.4851 1.77436 33 1.14307 33C0.511775 33 0 32.4851 0 31.85V29.55C0 26.3744 2.55885 23.8 5.71534 23.8H14.8599ZM21.0486 18.1435C21.4951 17.6944 22.2187 17.6944 22.6652 18.1435C23.0773 18.558 23.1089 19.2104 22.7603 19.6615L22.6652 19.7698L19.4321 23.0225C19.02 23.437 18.3716 23.4689 17.9232 23.1181L17.8155 23.0225L16.199 21.3962C15.7526 20.9471 15.7526 20.2189 16.199 19.7698C16.611 19.3553 17.2594 19.3234 17.7078 19.6742L17.8155 19.7698L18.6238 20.583L21.0486 18.1435ZM10.2876 10C13.4441 10 16.003 12.5744 16.003 15.75C16.003 18.9256 13.4441 21.5 10.2876 21.5C7.13113 21.5 4.57227 18.9256 4.57227 15.75C4.57227 12.5744 7.13113 10 10.2876 10ZM10.2876 12.3C8.39367 12.3 6.85841 13.8446 6.85841 15.75C6.85841 17.6554 8.39367 19.2 10.2876 19.2C12.1816 19.2 13.7168 17.6554 13.7168 15.75C13.7168 13.8446 12.1816 12.3 10.2876 12.3Z" fill="black" />
                          </svg>
                        </div>
                        <h5>Automated Follow-Ups: </h5>
                        <p>Set up automated follow-up campaigns to keep leads engaged.</p>

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="item">
                        <div className="img-box">
                          <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                          <svg className='img-fluid icon' width="50" height="44" viewBox="0 0 50 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.472 10.3773C27.1406 10.168 26.7616 10.0414 26.3683 10.0085C25.975 9.97573 25.5794 10.0377 25.2164 10.1891L13.3215 14.7626C13.0399 14.8745 12.7387 14.932 12.4347 14.932H4.81965C4.18053 14.932 3.56758 15.1798 3.11565 15.621C2.66372 16.0622 2.40983 16.6606 2.40983 17.2846V17.4728H0V23.119H2.40983V23.3637C2.42493 23.9778 2.68547 24.5618 3.13577 24.9909C3.58608 25.4199 4.19042 25.66 4.81965 25.6599L7.71144 31.6449C7.90726 32.0479 8.21554 32.3889 8.60088 32.6286C8.98623 32.8683 9.43301 32.997 9.88993 33H11.1045C11.7403 32.995 12.3483 32.745 12.796 32.3043C13.2438 31.8637 13.495 31.2681 13.495 30.6474V25.8857L25.2164 30.4592C25.5047 30.5712 25.8122 30.6287 26.1225 30.6286C26.6039 30.621 27.0727 30.4772 27.472 30.2145C27.7891 30.0055 28.0507 29.7258 28.235 29.3985C28.4194 29.0712 28.5213 28.7058 28.5323 28.3324V12.3159C28.5306 11.9329 28.4331 11.5561 28.2483 11.2183C28.0635 10.8805 27.797 10.5918 27.472 10.3773ZM11.0852 17.2846V23.3637H4.81965V17.2846H11.0852ZM11.0852 30.6474H9.87065L7.49938 25.6599H11.0852V30.6474ZM14.2083 23.646C13.9794 23.5318 13.7408 23.4374 13.495 23.3637V17.1528C13.7384 17.1038 13.977 17.0345 14.2083 16.9458L26.1225 12.3159V28.276L14.2083 23.646ZM28.5902 17.9433V22.6485C29.2293 22.6485 29.8422 22.4007 30.2942 21.9595C30.7461 21.5183 31 20.9199 31 20.2959C31 19.672 30.7461 19.0736 30.2942 18.6324C29.8422 18.1912 29.2293 17.9433 28.5902 17.9433Z" fill="black" />
                          </svg>

                        </div>
                        <h5>Marketing Campaigns: </h5>
                        <p>Plan and execute marketing campaigns directly from the CRM.</p>

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="item">
                        <div className="img-box">
                          <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                       <img className='img-fluid icon' src={require('../../assets/images/resources/user-management.png')} alt="" />
                        </div>
                        <h5>User Management: </h5>
                        <p>Control user roles with Super Admin, Admin, and Normal user access levels.</p>

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="item">
                        <div className="img-box">
                          <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                         <img className='img-fluid icon' src={require('../../assets/images/resources/unlimted-user.png')} alt="" />

                        </div>
                        <h5>Unlimited Users: </h5>
                        <p>Enjoy unlimited user access at no extra cost, with role-based control to ensure security.</p>

                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </>
  )
}
