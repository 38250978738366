import React from 'react'
import ResourceBanner from '../components/resourceBanner/ResourceBanner'
import GrowingBuisiness from '../components/growingBuisiness/GrowingBuisiness'
import CtaTwo from '../components/cta/CtaTwo'
import TouchPoint from '../components/touchPoint/TouchPoint'
import WeHelp from '../components/weHelp/WeHelp'
import Portfolio from '../components/portfolio/Portfolio'
import Market from '../components/market/Market'
import { Helmet } from "react-helmet";

export default function Resources() {
    return (
        <>

            {/* META TAGS */}
            <Helmet>
                <title>Custom CRM Software | CRM Development Services India</title>
                <meta name="description" content="Custom CRM software by ZimplCRM, tailored to fit your unique business needs. Improve customer management and boost productivity with our CRM solutions." />
                <meta name="keywords" content="Custom CRM Software" />
                <link rel="canonical" href="https://zimplcrm.com/resources" />
            </Helmet>


            <ResourceBanner />
            <GrowingBuisiness />
            <Market />
            <TouchPoint />
            <WeHelp />
            <Portfolio />
            <CtaTwo />


        </>
    )
}
