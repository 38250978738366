// HomePage.js
import React from 'react';
import Faq from './Faq';
import faqContent from './faqContent.json';

const HomePage = () => {
    const content = faqContent.home;

    return (
     
            <Faq content={content} />
 
    );
};

export default HomePage;
