import React, { useEffect } from 'react';
import './OfficeLocation.css'
import { useLocation } from 'react-router-dom';

export default function OfficeLocation() {


    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>

            <div id='ourlocations' className="officeLocation" data-scroll-section>
                <div className="blue-bg-box">
                    <div className="container cust-container">
                        <div className="inner-heading-box-white"><h2>Our offices around the world</h2>
                            <img src={require('../../assets/images/underline-white.png')} alt="" />
                            <p>Feel free to connect with one of our global offices, or simply send us a charming postcard. We’d love to hear from you!</p>
                        </div>

                        <div className="content-box">
                            <div className="row g-5">
                                <div className="col-lg-6">
                                    <div className="left">
                                        <img className='img-fluid' src={require('../../assets/images/about/office-location2.png')} alt="" />

                                        <div className="location-box">

                                            <div className="location-box-wrap">
                                            <div className="country-box">
                                                <h3>India</h3>
                                                <img className='img-fluid' src={require('../../assets/images/about/flag1.png')} alt="" />

                                            </div>
                                            <address>
                                                Zinbay India Pvt Ltd,
                                                12A2, Transasia Cyber Park,
                                                Infopark Phase-II,SEZ,
                                                Brahmapuram P.O,Kochi-682303
                                                Kerala, India.
                                            </address>
                                            <div className='connect-footer'>
                                                <p><img className='img-fluid phone' src={require('../../assets/images/about/phone.png')} alt="" /> <a href="tel:+919995907220">+91 999 590-7220</a> </p>
                                                <p><img className='img-fluid email' src={require('../../assets/images/about/email.png')} alt="" /> <a href="mailto:office.india@zimplcrm.com">office.india@zimplcrm.com</a> </p>
                                            </div>

                                            </div>

                                          

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="right">
                                        <img className='img-fluid' src={require('../../assets/images/about/office-location.png')} alt="" />


                                        <div className="location-box">

                                        <div className="location-box-wrap">
                                            <div className="country-box">
                                                <h3>United States</h3>
                                                <img className='img-fluid' src={require('../../assets/images/about/flag2.png')} alt="" />

                                            </div>
                                            <address>
                                                Zimpl Technologies LLC,
                                                7090 N Oracle Rd,
                                                Ste 178  #3090,
                                                Tucson , AZ 85704
                                                United States
                                            </address>
                                            <div className='connect-footer'>
                                            <p><img className='img-fluid phone' src={require('../../assets/images/about/phone.png')} alt="" /> <a href="tel:+15205347459"> +1 520 534-7459</a></p>
                                            <p><img className='img-fluid phone' src={require('../../assets/images/about/email.png')} alt="" /><a href="mailto:office.us@zimplcrm.com"> office.us@zimplcrm.com </a></p>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
