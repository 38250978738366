import React from 'react'
import './brand.css'

export default function Brand() {
  return (
    <>
      <div className="container cust-container" data-scroll-section>
        <div className="brand">
          <img className='img-fluid' src={require('../../assets/images/client/client1.png')} alt="" />
          {/* <img className='img-fluid' src={require('../../assets/images/client/client2.png')} alt="" /> */}
          {/* <img className='img-fluid' src={require('../../assets/images/client/client3.png')} alt="" /> */}
          <img className='img-fluid' src={require('../../assets/images/client/client4.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client5.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client6.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client7.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client8.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client9.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client10.png')} alt="" />
          <img className='img-fluid' src={require('../../assets/images/client/client11.png')} alt="" />
        </div>
      </div>

    </>
  )
}
