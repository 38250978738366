import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css';

export default function Contact() {
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handle = (e) => {
        const { id, value } = e.target;
        setData(prevData => ({ ...prevData, [id]: value }));
    };

    const submit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        try {
            // Convert data to URLSearchParams
            const params = new URLSearchParams();
            params.append('name', data.name);
            params.append('email', data.email);
            params.append('phone', data.phone);
            params.append('message', data.message);
    
            // Send POST request with form data
            const response = await axios.post('https://emailapi.zimpleads.com/contact_zimplcrm.php', params);
            console.log('Response:', response.data);
            setResponseMessage('Message sent successfully!');
    
            // Clear input fields
            setData({
                name: '',
                email: '',
                phone: '',
                message: ''
            });
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('There was an error sending your message. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    

    return (
        <div className="contact" data-scroll-section>
            <div className="container cust-container">
                <div className="contact-box">
                    <div className="row">
                        <div className="col-lg-6">
                            <img className='img-fluid bg-contact' src={require('../../assets/images/contact/bg.png')} alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="right">
                                <h2>Get in Touch</h2>
                                <p>Reach out to us today and let's start a conversation. Together, we can explore how our solutions can meet your needs, drive your business forward, and achieve your goals. We're here to listen, advise, and support you every step of the way. Get in touch, and let's make great things happen together!</p>

                                <form onSubmit={submit} method='POST'>
                                    <span className='form-submit-response'>{responseMessage}</span>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>Full Name</label>
                                            <input
                                                onChange={handle}
                                                id='name'
                                                value={data.name}
                                                type="text"
                                                required
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <label>Email Address</label>
                                            <input
                                                onChange={handle}
                                                id='email'
                                                value={data.email}
                                                type="email"
                                                required
                                            />
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Mobile Number</label>
                                            <input
                                                onChange={handle}
                                                id='phone'
                                                value={data.phone}
                                                type="tel"
                                                required
                                            />
                                        </div>

                                        <div className="col-lg-12">
                                            <label htmlFor="message">Messages</label>
                                            <textarea
                                                onChange={handle}
                                                id='message'
                                                value={data.message}
                                                placeholder='Write your messages here'
                                                name="message"
                                            ></textarea>
                                        </div>
                                        <div className="col-lg-12">
                                            <button type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? "Sending..." : "Send Message"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
