import React from 'react'
import './EmployeeTestimonial.css'

import 'swiper/css/free-mode';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/bundle';

import Card from 'react-bootstrap/Card';

export default function EmployeeTestimonial() {
    return (
        <>
            <div className="employee-testimonial" data-scroll-section>
                <div className="container cust-container">
                    <div className="inner-heading-box">

                        <h2>Our CRM solutions have proven to be essential for businesses worldwide.</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>Our CRM system is trusted by companies in diverse industries across five countries, reflecting our commitment to delivering reliable, effective, and innovative solutions that drive business success. </p>
                    </div>
                    <Swiper

                        loop={true}
                        grabCursor={true}
                        mousewheel={true}
                        freeMode={true}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        // }}
                        modules={[FreeMode, Autoplay]}
                        className="EmployeeTestimonial"

                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },

                            999: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },

                            // when window width is >= 1024px
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },

                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 30
                            }
                        }}

                    >
                        <SwiperSlide>
                            <Card>
                                <div className='img-box'>
                                    <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/testimonial/employee.png')} />
                                </div>

                                <Card.Body>
                                    <img className='img-fluid quote' src={require('../../assets/images/testimonial/quote.png')} alt="" />
                                    <Card.Title>ZimplCRM has revolutionized the way we handle our customer relationships. The user-friendly interface and powerful features have significantly boosted our productivity. I highly recommend it! </Card.Title>
                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <h6>John Davis</h6>
                                    <p>March 17, 2024</p></Card.Footer>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card>
                                <div className='img-box'>
                                    <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/testimonial/employee2.png')} />
                                </div>

                                <Card.Body>
                                    <img className='img-fluid quote' src={require('../../assets/images/testimonial/quote.png')} alt="" />
                                    <Card.Title>Since we started using ZimplCRM, our lead management process has become seamless. The automated follow-ups and detailed analytics have made a world of difference for our sales team. </Card.Title>
                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <h6>Michael Thompson</h6>
                                    <p>April 20,2024</p></Card.Footer>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card>
                                <div className='img-box'>
                                    <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/testimonial/employee3.png')} />
                                </div>

                                <Card.Body>
                                    <img className='img-fluid quote' src={require('../../assets/images/testimonial/quote.png')} alt="" />
                                    <Card.Title>The customizable dashboards and robust security features of ZimplCRM give us confidence in our data management. It’s a must-have tool for any growing business. </Card.Title>
                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <h6>Emma Rodriguez</h6>
                                    <p>January 7, 2024</p></Card.Footer>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card>
                                <div className='img-box'>
                                    <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/testimonial/employee4.png')} />
                                </div>

                                <Card.Body>
                                    <img className='img-fluid quote' src={require('../../assets/images/testimonial/quote.png')} alt="" />
                                    <Card.Title>ZimplCRM's comprehensive support and continuous updates ensure that we always have the latest features and the best service. It's an indispensable part of our operations.</Card.Title>
                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <h6>David Johnson</h6>
                                    <p>February 11, 2024</p></Card.Footer>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card>
                                <div className='img-box'>
                                    <Card.Img variant="top" className='img-fluid' src={require('../../assets/images/testimonial/employee2.png')} />
                                </div>

                                <Card.Body>
                                    <img className='img-fluid quote' src={require('../../assets/images/testimonial/quote.png')} alt="" />
                                    <Card.Title>The flexibility and scalability of ZimplCRM have made it easy for us to adapt as our business grows. We’ve seen a marked improvement in our customer engagement and overall efficiency. </Card.Title>
                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <h6>Chris Brown</h6>
                                    <p>May 3, 2024</p></Card.Footer>
                            </Card>
                        </SwiperSlide>

                    </Swiper>

                </div>

            </div>
        </>
    )
}
