import React from 'react'
import FeatureBanner from '../components/featurePage/FeatureBanner.js';
import FeatureOne from '../components/featurePage/Feature1.js';
import TypeOfFeatures from '../components/featurePage/TypeOfFeatures.js';

import Testimonial from '../components/testimonial/EmployeeTestimonial.js'
import Brand from '../components/brand/Brand.js';
import CtaTwo from '../components/cta/CtaTwo.js';
import { Helmet } from "react-helmet";


export default function FeaturePage() {
  return (
    <>
      <Helmet>
                <title>Top CRM Features  | Best CRM Management Software | Zimpl Crm</title>
                <meta name="description" content="Maximize your productivity with the best CRM management software. Explore powerful CRM features designed to enhance customer satisfaction and business efficiency" />
                <meta name="keywords" content="crm features, CRM management software" />
                <link rel="canonical" href="https://zimplcrm.com/features" />
            </Helmet>
		<FeatureBanner />
        <FeatureOne />

        <TypeOfFeatures />
        <Testimonial/>
        <Brand/>
        <CtaTwo/> 
    </>
  )
}
