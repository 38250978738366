import React, { useEffect } from 'react';
import './OurGoal.css'
import { useLocation } from 'react-router-dom';

export default function OurGoal() {


    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <>
            <div id="ourgoal" className="ourGoal" data-scroll-section >
                <div className="container cust-container">

                    <div className="inner-heading-box">
                        <h2>We Help Startups and SMBs Thrive</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>Empowering small businesses with the tools they need to achieve sustainable growth and success.</p>
                    </div>

                    <div className="row g-lg-5">
                        <div className="col-lg-4">
                            <div className='ourGoal-left'>

                            </div>
                            {/* <img className='img-fluid' src={require('../../assets/images/about/ourGoal.png')} alt="" /> */}
                        </div>

                        <div className="col-lg-8">

                            <div className="right">
                                <div className="inner-subheading-box">
                                    <h3>Our Goal</h3>
                                </div>

                                <p className='heading-para'>
                                    Our goal is to provide startups and small to medium-sized businesses (SMBs) with a robust, scalable CRM solution that simplifies customer relationship management, enhances productivity, and drives business growth. We aim to empower businesses at every stage of their journey with tools that are intuitive, powerful, and adaptable to their specific needs. At ZimplCRM, we understand that managing customer relationships effectively is crucial for success. That's why we've designed our CRM to be user-friendly and feature-rich, ensuring that even the smallest teams can leverage the same capabilities as larger enterprises. We offer powerful CRM development services designed to empower businesses of all sizes. From managing leads and contacts to automating workflows and generating insightful reports, our CRM covers all aspects of customer relationship management.
                                </p>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    User-Friendly Interface
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Scalable Solutions
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Comprehensive Features                                    </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Enhanced Security                                    </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Unlimited Users                                    </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Customizable Dashboards        </p>                          </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Automated Workflows
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Advanced Reporting
                                                </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Integration Capabilities                                    </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Exceptional Support                                   </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Affordable Pricing                                    </p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                                <p>
                                                    Free Trial Available        </p>                          </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}
