import React from 'react'
import './ShortTestimonial.css'
export default function ShortTestimonial() {
    return (
        <>
            <div className="short-testimonial" data-scroll-section>
                <div className="box">
                    <div className="box-body">
                        <img className='img-fluid' src={require('../../assets/images/testimonial/quote2.png')} alt="" />
                        <p>ZimplCRM has completely revolutionized our customer relationship management. As a burgeoning startup, finding the right CRM was crucial. ZimplCRM not only met but exceeded our expectations. Thanks to ZimplCRM, our productivity and customer engagement have soared.</p>
                    </div>

                    <div className="box-footer">
                        <img className='img-fluid' src={require('../../assets/images/testimonial/shortTestimonial-auther.png')} alt="" />
                        <div className="text-box">
                            <h5>Michael Johnson</h5>
                            <p>BrightStart Solutions</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
