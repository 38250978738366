import React from 'react'
import Logo from '../../assets/images/logo.svg'
import './Footer.css'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
export default function Footer() {
    return (
        <>
            <div className="footer" data-scroll-section>
                <Container className='cust-container'>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="box1">
                                <Link to='/'>
                                    <img src={Logo} alt="" />
                                </Link>
                                <p>Empowering Your Business with Innovative CRM Solutions.
								Stay Connected, Stay Ahead.</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-4 col-6">
                                    <div className='link-box'>
                                        <h6>Product</h6>
                                        <ul>
                                            <li><Link to='/features'>Features</Link></li>
                                            <li><Link to='/pricing'>Pricing</Link></li>
                                            <li><Link>Case studies</Link></li>
                                            <li><Link>Reviews</Link></li>
                                            <li><Link>Updates</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <div className='link-box'>
                                        <h6>Company</h6>
                                        <ul>
                                            <li><Link to='/about'>About Us</Link></li>
                                            <li><Link to='/contactus'>Contact Us</Link></li>
                                            <li><Link to='/about#ourlocations'>Our Locations</Link></li>
                                            <li><Link to='/about#ourgoal'>Our Goal</Link></li>
                                            <li><a href='https://zimplcrm.com/blog/' target="_blank" rel="noopener noreferrer">Blog</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <div className='link-box'>
                                        <h6>Support</h6>
                                        <ul>
                                            <li><Link to='https://login.zimplcrm.com/login'>Getting Started</Link></li>
                                            <li><Link to='https://login.zimplcrm.com/login'>Help center</Link></li>
                                            <li><Link to='https://status.zimplcrm.com'>Service status</Link></li>
                                            <li><Link to='https://login.zimplcrm.com/login'>Report a bug</Link></li>
                                            <li><Link to='https://login.zimplcrm.com/login'>Chat support</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="box2">
                                <h6>
                                    Subscribe to our newsletter
                                </h6>
                                <p>Stay updated with the latest news and exclusive offers from ZimplCRM. Subscribe to our newsletter and never miss an update!</p>
                                <input type="text" placeholder='Enter your email' />
                                <button className='btn-primary'>Subscribe</button>
                            </div>
                        </div>
                    </div>


                    <div className="copy-right">
                <p>Copyright © 2024 ZimplCRM | All Rights Reserved </p>

                <div className="social-media">
                    <a href="https://www.facebook.com/people/Zimpl-CRM/61561189392489/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/images/home/fb.png')} className="img-fluid" alt="" /></a>
                    <a href="https://www.instagram.com/zimplcrm?igsh=MTZpdmh2aWtsaXZldg==" target="_blank" rel="noopener noreferrer"> <img src={require('../../assets/images/home/instagram.png')}  className="img-fluid" alt="" /></a>
                    <a href="https://www.facebook.com/profile.php?id=61561189392489&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/images/home/linkedin.png')}  className="img-fluid" alt="" /></a>
                    <a href="https://www.youtube.com/@zimplcrm" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/images/home/youtube.png')}  className="img-fluid" alt="" /></a> 
                </div>
            </div>

                </Container>

            </div>
        </>
    )
}
