import React from 'react'
import './Why.css'
// import useAOS from '../../CustomHook/useAOS';
import { Link } from 'react-router-dom';

export default function Why() {
    return (
        <>
          
          <div className="container cust-container" data-scroll-section>
          <div className="why-box">
                <div className="heading-box">
                    <h6>Why choose Zimpl CRM!</h6>
                    <h2> Satisfied clientele. Motivated workforce. That's the essence of making a difference.</h2>
                    <p>Delighted clients. Inspired team members. This encapsulates the profound effect of positive engagement and synergy within an organization, where the satisfaction of customers fuels the energy of employees, leading to impactful outcomes and meaningful contributions.</p>
                </div>

                <div className="why-items">
                    <div className="why-item">
                        <h2>1</h2>
                        <div className="right">
                            <h4>Intuitive User Experience</h4>
                            <p>Zimpl CRM offers an intuitive and user-friendly interface, ensuring smooth navigation and easy adoption for teams of all sizes. Its simplicity enhances productivity and efficiency, allowing users to focus on driving sales and building relationships rather than struggling with complex software.</p>
                            <Link to='/contactus'>Learn More</Link>
                        </div>
                    </div>

                    <div className="why-item">
                        <h2>2</h2>
                        <div className="right">
                            <h4>Robust Feature Set</h4>
                            <p>Zimpl CRM provides a comprehensive suite of features tailored to streamline sales and marketing processes. From customizable pipelines and automated follow-ups to lead scoring and detailed reporting, it equips businesses with the tools they need to manage leads effectively, nurture relationships, and drive revenue growth.</p>
                            <Link to='/contactus'>Learn More</Link>
                        </div>
                    </div>

                    <div className="why-item">
                        <h2>3</h2>
                        <div className="right">
                            <h4>Scalability and Flexibility</h4>
                            <p>Whether you're a small startup or a large enterprise, Zimpl CRM scales with your business needs. Its flexible architecture allows for easy customization and integration with other tools and systems, ensuring seamless alignment with your unique workflows and requirements as your business evolves and grows.</p>
                            <Link to='/contactus'>Learn More</Link>
                        </div>
                    </div>
                
                </div>
            </div>
            </div> 

            
        </>
    )
}
