import React, { useState } from 'react';
import './Navbar.css';
import Logo from '../../assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {

    // Navbar closing after Link Change
    const [expanded, setExpanded] = useState(false);

    const handleLinkClick = () => {
        setExpanded(false);
    };

    const location = useLocation();


    // Language Transilate
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://cdn.gtranslate.net/widgets/latest/dwf.js';
    //     script.defer = true;
    //     document.body.appendChild(script);

    //     window.gtranslateSettings = {
    //         default_language: "en",
    //         languages: ["en", "fr", "de", "it", "es", "ml", "hi", "ta"],
    //         wrapper_selector: ".gtranslate_wrapper"
    //     };

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    // const changeLanguage = (language) => {
    //     const event = new Event('change');
    //     const select = document.querySelector('.gtranslate_wrapper select');
    //     if (select) {
    //         select.value = language;
    //         select.dispatchEvent(event);
    //     }
    // };


    return (
        <>


            <nav className="navbar navbar-expand-lg" data-scroll-section>
                <div className="container-fluid">
                    <Link className="navbar-brand" to='/' onClick={handleLinkClick}>
                      
                            <img src={Logo} alt="" />
                      
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={expanded ? 'true' : 'false'} aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>

                        <img className='hamburger' src={require('../../assets/images/hamburger.png')} alt="" />
                    </button>
                    <div className={"collapse navbar-collapse" + (expanded ? ' show' : '')} id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto">


                            <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                                <Link className="nav-link" to='/about' onClick={handleLinkClick}>
                                   About
                                </Link>

                            </li>
                            <li className={`nav-item ${location.pathname === '/features' ? 'active' : ''}`}>
                                <Link className="nav-link" to='/features' onClick={handleLinkClick}>
                                   Features
                                </Link>

                            </li>
                             <li className={`nav-item ${location.pathname === '/whyZimplCrm' ? 'active' : ''}`}>
                                <Link className="nav-link" to='/whyZimplCrm' onClick={handleLinkClick}>
                                   Why Zimpl CRM?
                                </Link>

                            </li>
                            <li className={`nav-item ${location.pathname === '/pricing' ? 'active' : ''}`}>
                                <Link className="nav-link" to='/pricing' onClick={handleLinkClick}>
                                    Pricing
                                </Link>

                            </li>

                            { <li className={`nav-item ${location.pathname === '/resources' ? 'active' : ''}`}>
                                <Link to='/resources'>
                                    <a className="nav-link" href="#">Resources</a>
                                </Link>

                            </li>  }



                            <li className={`nav-item ${location.pathname === '/contactus' ? 'active' : ''}`}>
                                <Link className="nav-link" to='/contactus' onClick={handleLinkClick}>
                                    Contact Us
                                </Link>

                            </li>

                        </ul>
                      
                        <form className="d-flex">

                            {/* LANGUAGE */}
                        {/* <div className="gtranslate_wrapper"></div>
                            
                            <select
                                defaultValue="en"
                                className="form-select"
                                onChange={(e) => changeLanguage(e.target.value)}
                            >
                                <option value="en">Eng(US)</option>
                                <option value="fr">French</option>
                                <option value="de">German</option>
                                <option value="it">Italian</option>
                                <option value="es">Spanish</option>
                                <option value="ml">Malayalam</option>
                                <option value="hi">Hindi</option>
                                <option value="ta">Tamil</option>
                            </select> */}
                            <a href='https://login.zimplcrm.com/login' className="btn login-btn"  >Login</a>
                        </form>
                    </div>
                </div>
            </nav>
        </>
    );
}
