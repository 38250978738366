import React from 'react'
import './Counter.css'

export default function Counter() {
    return (
        <>
            <div className="counter" data-scroll-section>
                <div className="container cust-container">
                    <div className="counters">
                        <div className="counter-box">
                            <h3>100+</h3>
                            <p>ZimplCRM is the choice of over 100+ companies worldwide.</p>
                        </div>

                        <div className="counter-box">
                            <h3>~30%</h3>
                            <p>Our users see an average 30% boost in productivity.</p>
                        </div>

                        <div className="counter-box">
                            <h3>99.9%</h3>
                            <p>Experience reliability with a 99.9% uptime guarantee.</p>
                        </div>

                        <div className="counter-box">
                            <h3>95%</h3>
                            <p>Our support team resolves 95% of issues within 24 hours.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
