import React from 'react'
import './TouchPoint.css'

export default function TouchPoint() {
    return (
        <>
            <div className="touchPoint">
                <div className="container cust-container">



                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="left">
                                <img className='img-fluid touchPointImg0' src={require('../../assets/images/resources/touchPoint0.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint1.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint2.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint3.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint4.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint5.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint6.png')} alt="" />
                                <img className='img-fluid' src={require('../../assets/images/resources/touchPoint7.png')} alt="" />




                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="right">
                                <div className="inner-subheading-box"><h3>Main Product Features</h3>
                                </div>

                                <p>Unlock the full potential of ZimplCRM with our comprehensive set of tools designed to streamline your business processes, enhance customer relationships, and drive growth. From managing leads to generating insightful reports, ZimplCRM provides everything you need to succeed in one powerful platform.</p>

                                <ul>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>Lead Management</p>
                                    </li>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>Deal Tracking</p>
                                    </li>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>Unified Inbox</p>
                                    </li>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>Task Scheduling</p>
                                    </li>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>Custom Dashboards</p>
                                    </li>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>Detailed Reports</p>
                                    </li>
                                </ul>

                                <a className="borderd-btn" href="/features">
                                    View All Features
                                </a>

                                <img className='img-fluid bg-blue' src={require('../../assets/images/resources/bg-blue.png')} alt="" />



                                {/* <svg width="62" height="37" viewBox="0 0 62 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="matching-line-path" d="M5 5H21C26.5228 5 31 9.47715 31 15V22C31 27.5228 35.4772 32 41 32H57" stroke="#0A65C2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"></path>
                                    <rect x="0.5" y="0.5" width="9" height="9" rx="2.5" fill="white" stroke="#0A65C2"></rect>
                                    <rect x="52.5" y="27.5" width="9" height="9" rx="2.5" fill="white" stroke="#0A65C2"></rect>
                                </svg> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
