import React, { useEffect, useRef } from 'react';
import './Banner.css';
import { Link } from 'react-router-dom';


export default function Banner() {

    return (
        <>
            <div className="container cust-container" data-scroll-section>
                <div className="banner" >
                    <div className='banner-text-box' >
                        <h1>
                            Best CRM Software in India: Efficient, Simple, and Powerful Solutions
                        </h1>

                        <p >
                            ZimplCRM, recognized as the <a href="/features">Best CRM Software in India</a>, is designed to help you boost sales effortlessly. With its powerful tools, you can easily track your sales, manage leads efficiently, and automate repetitive tasks, giving you more time to focus on growth. By streamlining your sales processes, ZimplCRM ensures you never miss a lead and keeps your team aligned for better productivity. Whether you're looking to scale faster or improve customer interactions, ZimplCRM equips you with everything you need to grow your business smarter and more effectively.
                        </p>

                        <Link to='/contactus' className='button'>
                            Try it free
                        </Link>
                    </div>

                    <div className="img-box text-center">
                        <img className='img-fluid' src={require('../../assets/images/home/banner.png')} alt="" />
                    </div>
                </div>
            </div>

        </>
    )
}
