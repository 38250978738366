import React from 'react';
import './Responsive.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LayoutRouts from './LayoutRouts/LayoutRouts';
// import Preloader from './components/preloader/Preloader';


function App() {


  return (
    <>
    {/* <Preloader/> */}
    <div className="App">
    
          <LayoutRouts></LayoutRouts>
      
    </div>
    </>
  );
}

export default App;
