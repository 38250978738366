import React from 'react'
import './NextLevel.css'

export default function NextLevel() {
    return (
        <>
            <div className="nextLevel" data-scroll-section>
                <div className="inner-heading-box ">
                    <h2>Key Benefits of Zimpl CRM</h2>
                    <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                </div>

                <div className="content-box">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2929 4.29289C22.6834 3.90237 23.3166 3.90237 23.7071 4.29289L27.7071 8.29289C28.0976 8.68342 28.0976 9.31658 27.7071 9.70711L15.7071 21.7071C15.5196 21.8946 15.2652 22 15 22H11C10.4477 22 10 21.5523 10 21V17C10 16.7348 10.1054 16.4804 10.2929 16.2929L22.2929 4.29289ZM12 17.4142V20H14.5858L25.5858 9L23 6.41421L12 17.4142Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2929 7.29289C19.6834 6.90237 20.3166 6.90237 20.7071 7.29289L24.7071 11.2929C25.0976 11.6834 25.0976 12.3166 24.7071 12.7071C24.3166 13.0976 23.6834 13.0976 23.2929 12.7071L19.2929 8.70711C18.9024 8.31658 18.9024 7.68342 19.2929 7.29289Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H16C16.5523 5 17 5.44772 17 6C17 6.55228 16.5523 7 16 7L5 7L5 27H25V16C25 15.4477 25.4477 15 26 15C26.5523 15 27 15.4477 27 16V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29H5C4.46957 29 3.96086 28.7893 3.58579 28.4142C3.21071 28.0391 3 27.5304 3 27V7C3 6.46957 3.21071 5.96086 3.58579 5.58579Z" fill="#4C64FD" />
                                    </svg>




                                    <h5>Easy Customization</h5>
                                    <p>Tailor the CRM to fit your business processes with simple, intuitive customization options. Ensure the system aligns perfectly with your needs. </p>
                                </div>


                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23 15H9C7.89543 15 7 15.8954 7 17V24C7 25.1046 7.89543 26 9 26H23C24.1046 26 25 25.1046 25 24V17C25 15.8954 24.1046 15 23 15Z" stroke="#4C64FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11 15V11C11 9.67392 11.5268 8.40215 12.4645 7.46447C13.4021 6.52678 14.6739 6 16 6C17.3261 6 18.5979 6.52678 19.5355 7.46447C20.4732 8.40215 21 9.67392 21 11V15" stroke="#4C64FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>



                                    <h5>Robust Security</h5>
                                    <p>Your data is safe with us. Zimpl CRM employs state-of-the-art security measures to protect your information and maintain confidentiality. </p>
                                </div>


                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 11C13.7909 11 12 12.7909 12 15C12 17.2091 13.7909 19 16 19C18.2091 19 20 17.2091 20 15C20 12.7909 18.2091 11 16 11ZM10 15C10 11.6863 12.6863 9 16 9C19.3137 9 22 11.6863 22 15C22 18.3137 19.3137 21 16 21C12.6863 21 10 18.3137 10 15Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7C3 5.89543 3.89543 5 5 5H27C28.1046 5 29 5.89543 29 7V25C29 26.1046 28.1046 27 27 27H5C3.89543 27 3 26.1046 3 25V7ZM27 7H5V25H27V7Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0005 20.9941C14.3451 20.9941 12.7305 21.5077 11.3792 22.4639C10.028 23.4201 9.00666 24.772 8.45602 26.3331C8.27231 26.8539 7.70117 27.1272 7.18033 26.9435C6.6595 26.7598 6.3862 26.1886 6.56991 25.6678C7.2582 23.7164 8.53488 22.0266 10.2239 20.8313C11.913 19.636 13.9313 18.9941 16.0005 18.9941C18.0697 18.9941 20.0879 19.636 21.777 20.8313C23.4661 22.0266 24.7427 23.7164 25.431 25.6678C25.6147 26.1886 25.3414 26.7598 24.8206 26.9435C24.2998 27.1272 23.7286 26.8539 23.5449 26.3331C22.9943 24.772 21.9729 23.4201 20.6217 22.4639C19.2704 21.5077 17.6558 20.9941 16.0005 20.9941Z" fill="#4C64FD" />
                                    </svg>




                                    <h5>User-Friendly Interface</h5>
                                    <p>Navigate with ease using our intuitive interface designed for users of all technical skill levels. Enjoy a smooth and efficient user experience.</p>
                                </div>


                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 14C13 13.4477 13.4477 13 14 13H22C22.5523 13 23 13.4477 23 14C23 14.5523 22.5523 15 22 15H14C13.4477 15 13 14.5523 13 14Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 18C13 17.4477 13.4477 17 14 17H22C22.5523 17 23 17.4477 23 18C23 18.5523 22.5523 19 22 19H14C13.4477 19 13 18.5523 13 18Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6C4 4.89543 4.89543 4 6 4H26C27.1046 4 28 4.89543 28 6V26C28 27.1046 27.1046 28 26 28H6C4.89543 28 4 27.1046 4 26V6ZM26 6H6V26H26V6Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4C10.5523 4 11 4.44772 11 5V27C11 27.5523 10.5523 28 10 28C9.44772 28 9 27.5523 9 27V5C9 4.44772 9.44772 4 10 4Z" fill="#4C64FD" />
                                    </svg>

                                    <h5>Comprehensive Reporting</h5>
                                    <p>Gain valuable insights with our advanced reporting tools, offering in-depth analysis. Make data-driven decisions with ease using our customizable and detailed reports. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C24.5523 23 25 23.4477 25 24V27C25 27.5523 24.5523 28 24 28C23.4477 28 23 27.5523 23 27V24C23 23.4477 23.4477 23 24 23Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 23C8.55228 23 9 23.4477 9 24V27C9 27.5523 8.55228 28 8 28C7.44772 28 7 27.5523 7 27V24C7 23.4477 7.44772 23 8 23Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6C3 4.89543 3.89543 4 5 4H27C28.1046 4 29 4.89543 29 6V23C29 24.1046 28.1046 25 27 25H5C3.89543 25 3 24.1046 3 23V6ZM27 6H5V23H27V6Z" fill="#4C64FD" />
                                        <path d="M19 17C20.1046 17 21 16.1046 21 15C21 13.8954 20.1046 13 19 13C17.8954 13 17 13.8954 17 15C17 16.1046 17.8954 17 19 17Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 15C18 14.4477 18.4477 14 19 14H28C28.5523 14 29 14.4477 29 15C29 15.5523 28.5523 16 28 16H19C18.4477 16 18 15.5523 18 15Z" fill="#4C64FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 11C16.7909 11 15 12.7909 15 15C15 17.2091 16.7909 19 19 19C21.2091 19 23 17.2091 23 15C23 12.7909 21.2091 11 19 11ZM13 15C13 11.6863 15.6863 9 19 9C22.3137 9 25 11.6863 25 15C25 18.3137 22.3137 21 19 21C15.6863 21 13 18.3137 13 15Z" fill="#4C64FD" />
                                    </svg>

                                    <h5>Seamless Integration</h5>
                                    <p>Integrate with your favorite tools and platforms effortlessly. Zimpl CRM ensures a smooth connection with your existing systems. </p>
                                </div>


                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26 6L15 17" stroke="#4C64FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M26 6L19 26L15 17L6 13L26 6Z" stroke="#4C64FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>



                                    <h5>Automated Follow-Ups</h5>
                                    <p>Never miss a follow-up with automated reminders and notifications. Ensure timely and consistent communication with your clients, improving customer satisfaction and retention.</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
