import React from 'react'
import './Market.css'
export default function Market() {
    return (
        <>
            <div className="market">
                <div className="container cust-container">
                    <div className="row g-0 g-lg-5">
                        <div className="col-lg-6">
                            <div className="left">
                                <h6>Get Up and Running Smoothly</h6>
                                <div className="inner-subheading-box"><h3>Transform Your Business in 90 Days</h3>
                                </div>
                                <p>Experience seamless onboarding and rapid growth with ZimplCRM. In your first 90 days, we'll guide you through setting up your account, integrating essential tools, and optimizing your workflows to ensure you get the most out of our powerful CRM solution. Our dedicated support team is here to assist you every step of the way, ensuring a smooth transition and immediate impact on your business operations.</p>
								
								<a className="borderd-btn"href="https://login.zimplcrm.com">Get Start Free</a>

                                    <div className="img-section">
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img1.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img2.png')} alt="" />
                                        <img className='img-fluid market-left-img9' src={require('../../assets/images/market/left-img3.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img4.png')} alt="" />
                                        <img className='img-fluid ' src={require('../../assets/images/market/left-img9.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img5.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img6.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img7.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/left-img8.png')} alt="" />
                                    </div>


                                    <div className="inner-subheading-box inner-subheading-box2"><h3>Unlock Success with Expert Guidance</h3>
                                </div>
                                <p>Our comprehensive guides are tailored specifically for small businesses, providing you with the tools and knowledge you need to thrive. From setting up your CRM to mastering advanced features, our step-by-step resources will help you streamline operations, improve customer relationships, and drive business growth. Start your journey to success with our expertly crafted guides, designed to empower and support your small business at every stage.</p>

                                    <a className="borderd-btn" href="https://login.zimplcrm.com">Get Start Free</a>

                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="right">
                                <img className='blue-shape img-fluid' src={require('../../assets/images/market/blue-shape.png')} alt="" />
                            <div className="inner-subheading-box inner-subheading-box2"><h3>Small Business  Automation Insights</h3>
                                </div>
                                <p>Dive into our Small Business Automation Blog for expert advice on automating your business processes. Discover how automation can save you time, reduce costs, and improve efficiency. Our blog covers a wide range of topics, from practical tips to advanced strategies, helping you leverage technology to optimize your operations and focus on what matters most – growing your business. Start reading today and see how automation can transform your small business.</p>

                                    <a className="borderd-btn" href="https://login.zimplcrm.com" >Explore More</a>


                                    <div className="img-section">
                                        <img className='right-img1 img-fluid' src={require('../../assets/images/market/right-img1.png')} alt="" />
                                        <img className='img-fluid' src={require('../../assets/images/market/right-img2.png')} alt="" />
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
