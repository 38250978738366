import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './PricingBanner.css'
import { Link } from 'react-router-dom';



export default function PricingBanner() {

    const [isChecked, setIsChecked] = useState(false);


    const [country, setCountry] = useState('IN');
    const [pricing, setPricing] = useState({});

    useEffect(() => {
        // Replace with your IP info API token
        const ipInfoUrl = 'https://ipinfo.io/json?token=a86ccbf207c245';

        axios.get(ipInfoUrl)
            .then(response => {
                const countryCode = response.data.country;
                setCountry(countryCode);
            })
            .catch(error => {
                console.error('Error fetching the IP information', error);
            });
    }, []);

    useEffect(() => {
        if (country) {
            // Example pricing data
            const pricingData = {
                INR: {
                    monthly: 2000,
                    annually: 22000,
                    advanced: 3500,
                    premium: 6000,
                    enterprise: 10000
                    
 
                },
                USD: {
                    monthly: 40,
                    annually: 440,
                    advanced: 75,
                    premium: 125,
                    enterprise: 250

                }
            };

            setPricing(pricingData[country === 'IN' ? 'INR' : 'USD']);
        }
    }, [country]);

    return (
        <>
            <div className="pricing-banner" data-scroll-section>
                <div className="inner-heading-box ">
                    <h1>Affordable CRM Software Prices in India</h1>
                    <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                    <p>Enjoy flexible pricing with Zimpl CRM: unlimited seats at an unchanging cost, ideal for scaling teams. All plans share the same features, so you can scale confidently without the worry of escalating CRM software prices in India or per-seat pricing. Therefore, you can allow access to all stakeholders in your organization so that they can tap into the full power of our CRM solutions.</p>
                </div>

                <div className="content-box">
                    <div className="button-box">
                        <h4>Annually</h4>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            <span className="btn-slider round"></span>
                        </label>
                        <h4>Monthly</h4>
                    </div>
                    {isChecked ? (
                        <div className="package-box">
                            <div className="row g-0">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100">
                                        <div className="tag-box">
                                            <img src={require('../../assets/images/pricing/icon1.png')} alt="" />
                                            <div className="tag-content">
                                                <p>To start with </p>
                                                <h4>Essential</h4>
                                            </div>
                                        </div>

                                        <h5>Offers a comprehensive set of features with a limit of 500 contact records, perfect for beginners or small businesses.</h5>


                                        {pricing ? (
                                            <p className='price'>{country === 'IN' ? `₹${pricing.monthly}` : `$${pricing.monthly}`} <span>/monthly</span></p>
                                        ) : (
                                            <p>loading</p>
                                        )}



                                        <div>
                                            <Link to='/contactus'>Get Started</Link>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="card right h-100">
                                        <h3>What’s included</h3>

                                        <ul className='package-list'>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>500 Contact Records</p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Up to 5 Custom Fields</p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Up to 2 Users</p>
                                            </li>


                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Training Videos & Weekly webinars</p>
                                            </li>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Email Support</p>
                                            </li>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>14-Day Free Trial</p>
                                            </li>


                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div className="package-box">
                            <div className="row g-0">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card h-100">
                                        <div className="tag-box">
                                            <img src={require('../../assets/images/pricing/icon1.png')} alt="" />
                                            <div className="tag-content">
                                                <p>To start with</p>
                                                <h4>Essential</h4>
                                            </div>
                                        </div>

                                        <h5>Offers a comprehensive set of features with a limit of 500 contact records, perfect for beginners or small businesses.</h5>
                                        {pricing ? (
                                            <p className='price'>{country === 'IN' ? `₹${pricing.annually}` : `$${pricing.annually}`} <span>/annually</span></p>
                                        ) : (
                                            <p>loading</p>
                                        )}
                                        <div>
                                            <Link to='/contactus'>Get Started</Link>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="card right h-100">
                                        <h3>What’s included</h3>

                                        <ul className='package-list'>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>500 Contact Records</p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Up to 5 Custom Fields</p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Up to 2 users</p>
                                            </li>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Training Videos & Weekly webinars</p>
                                            </li>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>Email Support</p>
                                            </li>
                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>1 Month Free!</p>
                                            </li>

                                            <li>
                                                <img className='img-fluid' src={require('../../assets/images/icon/list.png')} alt="" />
                                                <p>14-Day Free Trial</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
                <div className="feature-btn">
                    <button>See All features</button>
                </div>

            </div>


            <div className="add-on-plans" data-scroll-section>
                <div className='cust-container container' >
                    <div className="inner-heading-box">
                        <h6>Empower Your Growth with Scalable CRM Solutions</h6>
                        <h2>Flexible CRM Plans for Every Business Need</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>Our CRM plans offer the same robust features across all tiers, including options for low budget CRM services in India. Enjoy unlimited seats and comprehensive tools tailored to your business size and requirements, from small startups to large enterprises. Choose the plan that fits your growth and start optimizing your customer relationships today. Plus, get 2 months free when you choose an annual plan!</p>
                    </div>

                    <div className="content-box">



                        <div className="row g-3">

                            <div className="col-lg-12 col-12 col-md-12 col-xl-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../../assets/images/pricing/plan.png')} alt="" />
                                    <div className="card-body advace">

                                        <h4>
                                            Advanced
                                        </h4>

                                        <p>Includes the same robust features with an increased limit of 1,000 contact records, ideal for growing businesses with intermediate needs. </p>


                                        <div className="include-box">

                                            
                                        {pricing ? (
                                            <h2 className='price'>{country === 'IN' ? `₹${pricing.advanced}` : `$${pricing.advanced}`} <span>/monthly</span></h2>
                                        ) : (
                                            <p>loading</p>
                                        )}

                                            {/* <h2 className='price'>₹3,500 <span>/monthly</span></h2> */}

                                            <h3>What’s included</h3>

                                            <ul className='package-list'>
                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>1,000 Contact Records</p>
                                                </li>

                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Upto 10 Custom Fields</p>
                                                </li>

                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Up to 4 Users</p>
                                                </li>


                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Training Videos & Weekly webinars</p>
                                                </li>

                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Email Support</p>
                                                </li>
                                            </ul>



                                        </div>

                                    </div>
                                    <div className="card-footer">
                                        <Link to='/contactus' className='addOnbtn'>Get Started</Link>
                                    </div>

                                </div>
                            </div>


                            <div className="col-lg-12 col-12 col-md-12 col-xl-4">
                                <div className="card">
                                <img className='img-fluid' src={require('../../assets/images/pricing/plan2.png')} alt="" />
                                    <div className="card-body advace">

                                    <h4>
                                                Premium
                                            </h4>

                                            <p>Provides all high-end features with an expanded limit of 2,000 contact records, suitable for businesses that require more capacity </p>


                                            <div className="include-box">
                                            {pricing ? (
                                            <h2 className='price'>{country === 'IN' ? `₹${pricing.premium}` : `$${pricing.premium}`} <span>/monthly</span></h2>
                                        ) : (
                                            <p>loading</p>
                                        )}

                                                <h3>What’s included</h3>

                                                <ul className='package-list'>
                                                    <li>
                                                        <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                        <p>2,000 Contact Records</p>
                                                    </li>

                                                    <li>
                                                        <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                        <p>Upto 15 Custom Fields</p>
                                                    </li>

                                                    <li>
                                                        <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                        <p>Unlimited Users</p>
                                                    </li>


                                                    <li>
                                                        <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                        <p>Onboarding Call, Training Videos & Weekly webinars</p>
                                                    </li>

                                                    <li>
                                                        <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                        <p>Email, Chat and Phone Support</p>
                                                    </li>
                                                </ul>
                                            </div>

                                    </div>
                                    <div className="card-footer">
                                        <Link to='/contactus' className='addOnbtn'>Get Started</Link>
                                    </div>

                                </div>
                            </div>


                            <div className="col-lg-12 col-12 col-md-12 col-xl-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../../assets/images/pricing/plan3.png')} alt="" />
                                    <div className="card-body advace">

                                    <h4>
                                                Enterprise
                                            </h4>

                                            <p>Delivers the full suite of top-tier features with a generous limit of 4,000 contact records, designed for large organizations with extensive requirements. </p>

                                            {pricing ? (
                                            <h2 className='price'>{country === 'IN' ? `₹${pricing.enterprise}` : `$${pricing.enterprise}`} <span>/monthly</span></h2>
                                        ) : (
                                            <p>loading</p>
                                        )}

                                            <h3>What’s included</h3>

                                            <ul className='package-list'>
                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>4,000 Contact Records</p>
                                                </li>

                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Upto 20 Custom Fields</p>
                                                </li>

                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Unlimited Users</p>
                                                </li>


                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Onboarding Call, Training Videos & Weekly webinars</p>
                                                </li>

                                                <li>
                                                    <img className='img-fluid' src={require('../../assets/images/icon/list2.png')} alt="" />
                                                    <p>Email, Chat and Phone Support</p>
                                                </li>
                                            </ul>

                                    </div>
                                    <div className="card-footer">
                                        <Link to='/contactus' className='addOnbtn'>Get Started</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
