import React from 'react'
import './PricingPackage.css'

import Container from 'react-bootstrap/Container';

export default function PricingPackage() {
    return (
        <>
            <div className="pricing-package" data-scroll-section>
                <div className="container cust-container">
                    <div className="inner-heading-box">
                        <h2>
                            We’ve got your back
                        </h2>

                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>At Zimpl CRM, we promise top-notch CRM solutions with flexible plans to fit your needs. Enjoy unlimited seats and comprehensive tools tailored for any business size. Here are nine key benefits you'll receive:</p>
                    </div>

                    <div className="content-box">
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon1.png')} alt="" />
                                    <div className="content">
                                        <h5>Unlimited Seats</h5>
                                        <p>Provide access to all stakeholders without worrying about extra costs per user. Our CRM supports your entire team.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon2.png')} alt="" />
                                    <div className="content">
                                        <h5>Comprehensive Features</h5>
                                        <p>Access the same powerful tools and functionalities across all plans, ensuring you have everything you need to manage your customer relationships.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon3.png')} alt="" />
                                    <div className="content">
                                        <h5>Integration Capabilities</h5>
                                        <p>Seamlessly integrate with other tools and platforms you use, enhancing your CRM experience and connectivity.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon4.png')} alt="" />
                                    <div className="content">
                                        <h5>Scalable Record Limits</h5>
                                        <p>Choose the plan that fits your business size, with flexible record limits to accommodate your growth from small startups to large enterprises.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon5.png')} alt="" />
                                    <div className="content">
                                        <h5>Enhanced Security</h5>
                                        <p>Benefit from advanced security features designed to protect your data and ensure compliance with industry standards.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon6.png')} alt="" />
                                    <div className="content">
                                        <h5>Reliable Support</h5>
                                        <p>Get the help you need with our dedicated support team, available through email, chat, and phone, depending on your plan.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon7.png')} alt="" />
                                    <div className="content">
                                        <h5>Customizable Dashboards</h5>
                                        <p>Tailor your CRM interface to fit your specific needs with customizable dashboards that provide at-a-glance insights.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon8.png')} alt="" />
                                    <div className="content">
                                        <h5>Automated Workflows</h5>
                                        <p>Streamline your processes with automation tools that reduce manual tasks and increase efficiency.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="item">
                                    <img src={require('../../assets/images/pricing/package-icon9.png')} alt="" />
                                    <div className="content">
                                        <h5>Advanced Reporting</h5>
                                        <p>Utilize robust reporting and analytics to gain deep insights into your customer data and business performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div></>
    )
}
