import React from 'react'
import './Cta.css'
import { Link } from 'react-router-dom'

export default function CtaTwo() {
    return (
        <>
        <div className="container cust-container" data-scroll-section>
        <div className="ctatwo"> 
                <img className='img-fluid lg' src={require('../../assets/images/cta/ctaTwo-bg.png')} alt="" />
                <img className='img-fluid sm' src={require('../../assets/images/cta/ctaTwo-bg-mob.png')} alt="" />
                <div className="content">
                    <h2>Join the Sales Experts</h2>
                    <p>Free 14-day Professional plan trial. Full access. No credit card required</p>

                    <Link to='/contactus'>
                        Try ZimplCRM Free
                    </Link>
                </div>
            </div>
        </div>
           
        </>
    )
}
