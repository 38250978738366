import React from 'react'
import './OurMission.css'

export default function OurMission() {
    return (
        <>
            <div className="ourMission" data-scroll-section>
                <div className="container cust-container">
                    <div className="row g-lg-5">
                        <div className="col-lg-8">
                            <div className="left">
                                <div className="inner-subheading-box">
                                    <h3>Our CRM mission statement</h3>
                                    <p>At ZimplCRM, our mission is to empower businesses of all sizes by providing innovative and user-friendly CRM solutions that drive growth, enhance productivity, and foster strong customer relationships. We are dedicated to creating a platform that is not only powerful and comprehensive but also intuitive and easy to use, ensuring that even the smallest teams can maximize their efficiency and effectiveness. Our commitment extends beyond technology; we aim to build lasting partnerships with our clients by offering exceptional support and continuous improvements. By understanding and anticipating the needs of modern businesses, we strive to deliver a CRM solution that adapts and scales with your growth, helping you achieve your goals and succeed in a competitive market.</p>
                                </div>

                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon1.png')} alt="" />
                                            </div>
                                            <h5>Customizable Workflows </h5>
                                            <p>Tailor your CRM processes to fit unique business needs.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon2.png')} alt="" />
                                            </div>
                                            <h5>Real-Time Analytics</h5>
                                            <p>Gain insights with up-to-the-minute data and reports.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon1.png')} alt="" />
                                            </div>
                                            <h5>Mobile Accessibility</h5>
                                            <p>Manage your CRM from anywhere with our mobile-friendly platform.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon2.png')} alt="" />
                                            </div>
                                            <h5>Integration Flexibility<sup>*</sup></h5>
                                            <p>Seamlessly connect with your favorite tools and applications.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon1.png')} alt="" />
                                            </div>
                                            <h5>Automated Follow-Ups</h5>
                                            <p>Enhance customer engagement with timely, automated communications.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon2.png')} alt="" />
                                            </div>
                                            <h5>Intuitive Onboarding</h5>
                                            <p>Get started quickly with our easy setup and onboarding process.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon1.png')} alt="" />
                                            </div>
                                            <h5>Continuous Updates</h5>
                                            <p>Benefit from regular updates and new feature releases.</p>

                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureOneIcon2.png')} alt="" />
                                            </div>
                                            <h5>Advanced Data Security</h5>
                                            <p>Ensure your information is safe with robust security protocols.</p>

                                        </div>
                                    </div>

                                    

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="right">

                            {/* <img className='img-fluid' src={require('../../assets/images/about/mission.png')} alt="" /> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
