import React from 'react'
import PricingBanner from '../components/pricingBanner/PricingBanner'
import AddOnplans from '../components/addOnPlans/AddOnplans'
import PricingPackage from '../components/pricingPackage/PricingPackage'
import CtaOne from '../components/cta/CtaOne.js'
import PricePageFaq from '../components/faq/PricePageFaq.js'
import { Helmet } from "react-helmet";

export default function Pricing() {
  return (
    <>
         {/* META TAGS */}
         <Helmet>
                <title>CRM Pricing | CRM Software Price in India</title>
                <meta name="description" content="Explore competitive and affordable CRM software prices in India designed for your needs. Maximize efficiency and growth with our flexible plans!" />
                <meta name="keywords" content="CRM pricing, CRM software price in India" />
                <link rel="canonical" href="https://zimplcrm.com/pricing" />
            </Helmet>

      <PricingBanner />
      {/* <AddOnplans /> */}
      <PricingPackage />
      <PricePageFaq/>
      <CtaOne/>
    </>
  )
}
