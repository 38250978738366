import React from 'react'
import './Feature.css'

import LeadManagementfeature from '../leadManagementFeature/LeadManagementfeature'

export default function TypeOfFeatures() {
    return (
        <>
            <div className="typeof-feature" data-scroll-section>
                <div className="container cust-container">
                    <div className="inner-heading-box">
                        <h2>Customizable and Intuitive</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>Tailor your CRM experience with our customizable dashboard, designed to fit your business needs perfectly. Personalize your workspace to prioritize the information and metrics that matter most to you.</p>
                    </div>

                    <div className="content-box">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12">
                                <div className="img-box">
                                    <img className='featuretwo1 img-fluid' src={require('../../assets/images/feature/featuretwo1.png')} alt="" />
                                    <img className='featuretwo2 img-fluid' src={require('../../assets/images/feature/featuretwo2.png')} alt="" />
                                    <img className='featuretwo3 img-fluid' src={require('../../assets/images/feature/featuretwo3.png')} alt="" />
                                </div>

                            </div>

                            <div className="col-xl-8 col-lg-12">
                                <div className="right">
                                    <div className="inner-subheading-box">
                                        <h5>CRM Features</h5>
                                        <h3>Customization and Automation</h3>
                                    </div>

                                    <p>Our CRM offers extensive customization options and powerful automation tools to enhance your workflow, making it easier to manage tasks and track progress.
                                    </p>
                                    <ul>
                                        <li><b>Modular Dashboard:</b> Customize your dashboard to display the metrics and information that matter most to your team, ensuring quick access to critical data.</li>
                                        <li><b>Task Management:</b> Integrated calendar and task management tools help you stay organized and ensure that important activities are completed on time.</li>
                                        <li><b>Custom Lead Statuses:</b> Define and track lead statuses specific to your sales process, providing clarity and structure to your pipeline.</li>
                                        <li><b>Custom Activity Timeline:</b> Keep a detailed log of all interactions and activities related to leads and deals, ensuring that you have a complete history at your fingertips.</li>
                                        <li><b>Custom Fields:</b> Create custom fields for leads and accounts to capture all necessary information, enabling more personalized and effective customer interactions.</li>
                                        <li><b>Short Codes and Templates:</b> Use short codes and pre-designed templates for streamlined communication, saving time and ensuring consistency.</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row bottom">
                        <div className="col-lg-3">
                            <h3>
                                 Integrations
                            </h3>
                            <h6>
                                Seamlessly integrate with external services like Twilio<sup>*</sup> for better communication capabilities, ensuring your CRM works well with the tools you use. Automate tasks, send SMS notifications, and connect with other business applications smoothly.
                            </h6>
                        </div>

                        <div className="col-lg-3">
                            <h3>
                                Analytics
                            </h3>
                            <h6>
                                Access a detailed analytics dashboard for real-time data insights. Visualize key metrics, track user activity, and monitor customer interactions effectively. Make quick, informed decisions to optimize sales strategies and stay competitive.
                            </h6>
                        </div>

                        <div className="col-lg-3">
                            <h3>
                                Reports
                            </h3>
                            <h6>
                                Generate detailed reports to monitor and analyze sales performance. Identify trends, measure sales tactic effectiveness, and find areas for improvement. Use customizable templates to create, share, and review comprehensive business reports.
                            </h6>
                        </div>

                        <div className="col-lg-3">
                            <h3>
                                Calendar
                            </h3>
                            <h6>
                                Manage your schedule and tasks efficiently with our integrated calendar. Sync appointments, set reminders, and organize your workflow in one place. Ensure you never miss a follow-up or meeting, helping you stay on top of responsibilities.
                            </h6>
                        </div>
                    </div>
                </div>


            </div>

<LeadManagementfeature/>
			
			<div className="typeof-feature" data-scroll-section>
                <div className="container cust-container">
                    <div className="inner-heading-box">
                        <h2>Add-On Features for Enhanced Functionality</h2>
                        <img src={require('../../assets/images/pricing/underline.png')} alt="" />
                        <p>We are continually enhancing our platform to make your tasks even easier. Our dedicated team is working on developing more add-on features designed to streamline your workflow and boost productivity. Stay tuned for updates as we roll out innovative tools and integrations tailored to meet your evolving needs, ensuring you have the most efficient and effective CRM experience possible.</p>
                    </div>
				</div>
			</div>

            <div className="contact-management" data-scroll-section>
                <div className="container cust-container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-0 order-md-0 order-1">
                            <div className="inner-subheading-box">
                                <h5>Upcoming Feature</h5>
                                <h3>Knowledge Base and Support<sup>*</sup></h3>
                            </div>
                            <p>
                                Provide excellent customer support with our integrated knowledge base and support management system. This feature empowers your customers to find answers quickly and efficiently, reducing the burden on your support team. By having a comprehensive repository of articles, FAQs, and troubleshooting guides, customers can easily access the information they need, resulting in faster resolution times and higher satisfaction. The support management system ensures that any remaining issues are tracked and handled smoothly, giving your team the tools they need to deliver exceptional service.
                            </p>


                            <div className="inner-subheading-box sales-automation">
                                <h5>Upcoming Feature</h5>
                                <h3>Document Management<sup>*</sup></h3>
                            </div>
                            <p>
                                Organize and manage your documents seamlessly within the CRM, ensuring easy access and collaboration for all team members. With our integrated document management system, you can store, categorize, and retrieve important files quickly and efficiently. This centralized repository allows your team to collaborate on documents in real-time, share insights, and make updates without leaving the CRM platform. By having all essential information at their fingertips, team members can work more cohesively, make informed decisions, and enhance overall productivity, leading to better outcomes for your business.
                            </p>
                        </div>

                        <div className="col-lg-6 order-lg-1 order-md-1 order-0">
                            <div className="right">
                                <img className='img-fluid img1' src={require('../../assets/images/feature/contact-management1.png')} alt="" />
                                <img className='img-fluid img2' src={require('../../assets/images/feature/contact-management2.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div className="contact-management" data-scroll-section>
                <div className="container cust-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="right">
                                <img className='img-fluid img2' src={require('../../assets/images/feature/contact-management3.png')} alt="" />
                                <img className='img-fluid img1' src={require('../../assets/images/feature/contact-management4.png')} alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="inner-subheading-box">
                                <h5>Upcoming Feature</h5>
                                <h3>Landing Page Builder<sup>*</sup></h3>
                            </div>
                            <p>
                                Create and customize landing pages effortlessly to capture more leads effectively, enhancing your marketing efforts and driving increased traffic to your site. Our intuitive landing page builder allows you to design visually appealing and highly functional pages tailored to your specific campaigns. With customizable templates and drag-and-drop functionality, you can quickly build pages that resonate with your target audience. These optimized landing pages help you convert visitors into leads by providing compelling calls-to-action and relevant content. By leveraging this tool, you can boost your marketing performance, track lead generation metrics, and refine your strategies to maximize engagement and conversions.
                            </p>


                            <div className="inner-subheading-box sales-automation">
                                <h5>Upcoming Feature</h5>
                                <h3>PPC Management Solutions<sup>*</sup></h3>
                            </div>
                            <p>
                                Optimize your pay-per-click (PPC) campaigns with our integrated management solutions, maximizing your ROI and ensuring that your advertising efforts are effective. Our comprehensive PPC management tools enable you to plan, execute, and monitor your campaigns with precision. By leveraging advanced targeting options, keyword optimization, and real-time analytics, you can fine-tune your ads to reach the right audience at the right time. Our system provides actionable insights and recommendations to help you allocate your budget efficiently, reduce costs, and improve conversion rates. With these robust tools, you can enhance the performance of your PPC campaigns, driving more qualified traffic to your site and achieving better returns on your advertising investment.
                            </p>
                        </div>

                    </div>
                </div>


            </div>
			<div className="contact-management" data-scroll-section>
                <div className="container cust-container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-0 order-md-0 order-1">
                            <div className="inner-subheading-box">
                                <h5>Upcoming Feature</h5>
                                <h3>Integrated Generative AI<sup>*</sup></h3>
                            </div>
                            <p>
                                Leverage AI to automate tasks and enhance decision-making processes, providing advanced insights and improving overall efficiency. Our AI-powered features include intelligent email and text templates that enable you to craft and send personalized replies to communications more effectively, ensuring each interaction feels tailored and relevant. These templates save time and reduce the cognitive load on your team, allowing them to focus on more strategic tasks.
							</p>
							<p>
								With automated follow-up campaigns, you can ensure timely and consistent interactions with leads and customers, keeping them engaged throughout their journey. AI helps schedule and send follow-up messages at the optimal times, increasing the chances of a positive response and improving overall customer satisfaction.
							</p>
							<p>
								Additionally, our AI tools analyze communication patterns and suggest the best responses based on historical data and customer behavior, further enhancing the personalization of your outreach efforts. This not only improves the efficiency of your communication but also helps build stronger relationships with your customers.
							</p>
							<p>
								By integrating these advanced AI capabilities, you can streamline your operations, maintain high levels of customer engagement, and drive better business outcomes, all while reducing manual effort and increasing productivity across your team.
                            </p>

                        </div>

                        <div className="col-lg-6 order-lg-1 order-md-1 order-0">
                            <div className="right">
                                <img className='img-fluid img1' src={require('../../assets/images/feature/contact-management1.png')} alt="" />
                                <img className='img-fluid img2' src={require('../../assets/images/feature/contact-management2.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="smartContactData" data-scroll-section>
                <div className="container cust-container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="left">
                                <img className='img-fluid' src={require('../../assets/images/feature/smartContactData.png')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="right">
                                <div className="inner-subheading-box">
                                    <h5>Tailored Solutions</h5>
                                    <h3>Optimized for Your Success</h3>
                                </div>

                                <p>
                                    Our CRM goes beyond just lead management software We understand that each business is unique, and our CRM offers a range of features that can be customized to fit your specific requirements. Whether you're looking to enhance lead management, streamline your sales process, or run effective marketing campaigns, our CRM has you covered. By integrating all your customer data and interactions in one place, we enable you to make informed decisions, improve productivity, and drive business growth. Our user-friendly interface and robust feature set ensure that you have everything you need to stay ahead of the competition and achieve your business goals.
                                </p>

                                <ul>
                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>
                                            Lead Management
                                        </p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>
                                            Deal Management
                                        </p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>
                                            Unified Inbox                                    </p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>
                                            Marketing Campaigns                                   </p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>
                                            User Management                                    </p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src={require('../../assets/images/feature/tickIcon.png')} alt="" />
                                        <p>
                                            Customizable Features        </p>                          </li>
                                </ul>

                                <button className='btn-primary'>
                                    Watch Video
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row bottom">
                        <div className="col-lg-3">
                            <h3>
                                Easy Pricing
                            </h3>
                            <h6>
                                Our pricing plans are straightforward and affordable, making it easy for businesses of all sizes to get started with our CRM. Enjoy transparent pricing with no hidden fees.
                            </h6>
                        </div>

                        <div className="col-lg-3">
                            <h3>
                                Training
                            </h3>
                            <h6>
                                Comprehensive training resources, user guides, and customer support services are available to ensure you get the most out of our CRM, helping you every step of the way.
                            </h6>
                        </div>

                        <div className="col-lg-3">
                            <h3>
                                Trial/Demo
                            </h3>
                            <h6>
                                Try our CRM with trial and demo accounts to see how it can benefit your business before committing. Experience all the features and see firsthand how our CRM can streamline your operations.
                            </h6>
                        </div>

                        <div className="col-lg-3">
                            <h3>
                                Support
                            </h3>
                            <h6>
                                We offer extensive training resources and user guides to help you make the most of our CRM, along with dedicated customer support services to assist with any issues you encounter.
                            </h6>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
