import React from 'react'
import Faq from './Faq';
import faqContent from './faqContent.json';

const PricePageFaq = () => {
  const content = faqContent.pricing;

  return (
      <div>
          <Faq content={content} />
      </div>
  );
};

export default PricePageFaq;