import React from 'react'
import './AboutBanner.css'

export default function AboutBanner() {
    return (
        <>
            <div className="about-banner" data-scroll-section>
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="left">
                                <h2>Our core values</h2>
                                <p>At ZimplCRM, our core values—customer-centricity, innovation, integrity, collaboration, sustainability, and excellence—drive everything we do. We are committed to delivering tailored solutions that meet our clients' specific needs while building long-term partnerships. Our CRM solutions equip businesses with the tools to manage customer data efficiently, automate engagement processes, and integrate sales and marketing activities. With real-time analytics, scalability, and robust security, our CRM platform streamlines operations, enhances customer experiences, and fuels business growth, empowering companies to achieve new heights.</p>

                                <img className='img-fluid' src={require('../../assets/images/about/banner.png')} alt="" />
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="right">

                                <h2>ZimplCRM Is Different</h2>
                                <p>Empowering Your Business with Tailored CRM Solutions.</p>

                                <div className="row">

                                    <div className="col-lg-6 col-md-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureBannerIcon1.png')} alt="" />
                                            </div>
                                            <h5>Unlimited Users Across All Plans </h5>
                                            <p>No additional costs for adding team members. Scale your team without worrying about extra charges.</p>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureBannerIcon2.png')} alt="" />
                                            </div>
                                            <h5>Same Comprehensive Features  </h5>
                                            <p>access powerful tools and functionalities, regardless of the plan you choose, ensuring you have everything you need to succeed.</p>

                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <img className="img-fluid icon" src={require('../../assets/images/feature/featureBannerIcon3.png')} alt="" />
                                            </div>
                                            <h5>Flexible Record Limits </h5>
                                            <p>Choose a plan that fits your business size with scalable record limits, from small startups to large enterprises.</p>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <svg className='img-fluid icon' width="60" height="54" viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.375 20.2003C5.90535 20.1667 6.55916 20.1667 7.4 20.1667H14.6C15.4408 20.1667 16.0947 20.1667 16.625 20.2003M5.375 20.2003C4.71314 20.2421 4.24358 20.3364 3.84278 20.5482C3.20773 20.8837 2.69143 21.4191 2.36785 22.0777C2 22.8264 2 23.8064 2 25.7667V27.4C2 29.3602 2 30.3402 2.36785 31.089C2.69143 31.7476 3.20773 32.283 3.84278 32.6185C4.56473 33 5.50982 33 7.4 33H14.6C16.4902 33 17.4352 33 18.1572 32.6185C18.7923 32.283 19.3086 31.7476 19.6321 31.089C20 30.3402 20 29.3602 20 27.4V25.7667C20 23.8064 20 22.8264 19.6321 22.0777C19.3086 21.4191 18.7923 20.8837 18.1572 20.5482C17.7564 20.3364 17.2868 20.2421 16.625 20.2003M5.375 20.2003V17.8333C5.375 14.6117 7.8934 12 11 12C14.1066 12 16.625 14.6117 16.625 17.8333V20.2003" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>
                                            <h5>Robust Security Measures </h5>
                                            <p>Advanced security features protect your data and ensure compliance with industry standards, giving you peace of mind.</p>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />

                                                <svg className='img-fluid icon' width="60" height="54" viewBox="0 0 50 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 29.5C10 30.5872 10 31.1308 10.1522 31.5596C10.3552 32.1313 10.7446 32.5856 11.2346 32.8224C11.6022 33 12.0681 33 13 33H17C17.9319 33 18.3978 33 18.7654 32.8224C19.2554 32.5856 19.6448 32.1313 19.8478 31.5596C20 31.1308 20 30.5872 20 29.5C20 28.4128 20 27.8692 19.8478 27.4404C19.6448 26.8687 19.2554 26.4144 18.7654 26.1776C18.3978 26 17.9319 26 17 26H13C12.0681 26 11.6022 26 11.2346 26.1776C10.7446 26.4144 10.3552 26.8687 10.1522 27.4404C10 27.8692 10 28.4128 10 29.5ZM10 29.5H8.2C7.07989 29.5 6.51984 29.5 6.09202 29.2457C5.71569 29.022 5.40973 28.665 5.21799 28.226C5 27.7269 5 27.0734 5 25.7667V19M5 19H17C17.9319 19 18.3978 19 18.7654 18.8224C19.2554 18.5856 19.6448 18.1313 19.8478 17.5596C20 17.1308 20 16.5872 20 15.5C20 14.4128 20 13.8692 19.8478 13.4404C19.6448 12.8687 19.2554 12.4144 18.7654 12.1776C18.3978 12 17.9319 12 17 12H5C4.06812 12 3.60218 12 3.23463 12.1776C2.74458 12.4144 2.35523 12.8687 2.15224 13.4404C2 13.8692 2 14.4128 2 15.5C2 16.5872 2 17.1308 2.15224 17.5596C2.35523 18.1313 2.74458 18.5856 3.23463 18.8224C3.60218 19 4.06812 19 5 19Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>
                                            <h5>Customizable Workflows </h5>
                                            <p>Tailor your CRM interface and automate processes to fit your specific needs, enhancing productivity and efficiency.</p>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="item">
                                            <div className="img-box">
                                                <img className='img-fluid dot-bg' src={require('../../assets/images/feature/dot-bg.png')} alt="" />
                                                <svg className='img-fluid icon' width="53" height="44" viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9794 20.225C20.6314 16.25 18.5212 10 11.9999 10C5.47856 10 3.36839 16.25 3.02043 20.225C1.7998 20.7409 0.994652 22.046 1.00003 23.5V25.25C1.00003 27.183 2.40714 28.75 4.14287 28.75C5.87859 28.75 7.28568 27.183 7.28568 25.25V23.5C7.28004 22.0774 6.50446 20.7991 5.32142 20.2625C5.54591 17.9625 6.64589 12.5 11.9999 12.5C17.3539 12.5 18.4427 17.9625 18.6671 20.2625C17.4865 20.8003 16.7148 22.0794 16.7141 23.5V25.25C16.719 26.5937 17.4118 27.8166 18.4988 28.4C18.0274 29.3875 16.8264 30.725 13.8744 31.125C13.2843 30.1271 12.1426 29.7337 11.1496 30.1861C10.1566 30.6385 9.58979 31.8102 9.79687 32.9825C10.004 34.1549 10.927 35 11.9999 35C12.8477 34.9948 13.6206 34.4581 13.9978 33.6125C18.8131 33 20.3508 30.2375 20.8334 28.6125C22.1392 28.1415 23.0173 26.7784 22.9997 25.25V23.5C23.0051 22.046 22.2 20.7409 20.9794 20.225ZM5.04081 25.25C5.04081 25.8023 4.63879 26.25 4.14287 26.25C3.64694 26.25 3.24492 25.8023 3.24492 25.25V23.5C3.24492 23.1427 3.41607 22.8126 3.69389 22.634C3.97172 22.4553 4.31401 22.4553 4.59184 22.634C4.86966 22.8126 5.04081 23.1427 5.04081 23.5V25.25ZM18.959 23.5C18.959 22.9477 19.361 22.5 19.8569 22.5C20.3528 22.5 20.7549 22.9477 20.7549 23.5V25.25C20.7549 25.8023 20.3528 26.25 19.8569 26.25C19.361 26.25 18.959 25.8023 18.959 25.25V23.5Z" fill="black" stroke="black" stroke-width="0.5" />
                                                </svg>


                                            </div>
                                            <h5>Exceptional Support </h5>
                                            <p>Benefit from dedicated support through email, chat, and phone, ensuring you get the help you need when you need it.</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
