import React from 'react'
import AboutBanner from '../components/aboutBanner/AboutBanner'
import EmployeeTestimonial from '../components/testimonial/EmployeeTestimonial'
import LeadManagementfeature from '../components/leadManagementFeature/LeadManagementfeature'
import CtaTwo from '../components/cta/CtaTwo'
import OurGoal from '../components/ourGoal/OurGoal'
import ShortTestimonial from '../components/testimonial/ShortTestimonial'
import OurMission from '../components/ourMission/OurMission'
import Counter from '../components/counter/Counter'
import HistroyOfCrm from '../components/historyOfCrm/HistroyOfCrm'
import OfficeLocation from '../components/officeLocation/OfficeLocation'

import { Helmet } from "react-helmet";


export default function AboutPage() {
    return (
        <>

            {/* META TAGS */}
            <Helmet>
                <title>Customer Relationship Management | CRM Solution | Zimplcrm</title>
                <meta name="description" content="We provide Customer Relationship Management to enhance customer interactions. Our CRM solutions optimize workflows and improve communication." />
                <meta name="keywords" content="customer relationship management, CRM Solution" />
                <link rel="canonical" href="https://zimplcrm.com/about" />
            </Helmet>

            <AboutBanner />
            <OurGoal />
            <ShortTestimonial />
            <OurMission />
            <Counter />
            <HistroyOfCrm />
            <EmployeeTestimonial />
            <LeadManagementfeature />
            <OfficeLocation></OfficeLocation>
            <CtaTwo />
        </>
    )
}
