import React from "react";
import "./ResourceBanner.css";

export default function ResourceBanner() {
  return (
    <>
      <div className="ResourceBanner">
        <div className="container cust-container">
          <div className="ResourceBanner-box">
            <div className="inner-heading-box">
              <h2>Unlock the Full Potential of ZimplCRM with Custom CRM Software</h2>
              <img
                src={require("../../assets/images/pricing/underline.png")}
                alt=""
              />
              <p>
                Discover a variety of resources tailored to help you maximize your use of ZimplCRM’s <a href="/features">Custom CRM Software</a>. Whether you're just getting started or looking to deepen your knowledge, we provide everything you need to succeed and unlock the full potential of your business.

              </p>
            </div>

            <div className="content-box">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-4">
                    <div className="card">
                      <img src={require('../../assets/images/resources/user-guide.png')} alt="" />

                     

                      <h4>User Guides</h4>
                      <p>
                        Comprehensive guides to help you set up and use ZimplCRM
                        effectively.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <img
                        src={require("../../assets/images/resources/video-tutorial.png")}
                        alt=""
                      />
                      <h4>Video Tutorials</h4>
                      <p>
                        Step-by-step video guides to walk you through key
                        features and integrations.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <img
                        src={require("../../assets/images/resources/faq.png")}
                        alt=""
                      />
                      <h4>FAQs</h4>
                      <p>
                        Quick answers to the most common questions about
                        ZimplCRM.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
